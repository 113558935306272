/**
 * Opções de Tipo de Transações
 */
export const paymentTypeList = [
  { id: "charge", name: "Venda", icon: "paid", color: "green" },
  {
    id: "charge_refunded",
    name: "Venda Estornada",
    icon: "cancel",
    color: "#e6153b",
  },
  { id: "transfer", name: "Transferência", icon: "paid", color: "" },
  {
    id: "transfer_refunded",
    name: "Transferência Estornada",
    icon: "cancel",
    color: "#e6153b",
  },
  {
    id: "deposit",
    name: "Depósito",
    icon: "forward",
    color: "green",
  },
  {
    id: "deposit_refunded",
    name: "Depósito Estornado",
    icon: "cancel",
    color: "#e6153b",
  },
  { id: "adjustment", name: "Ajuste em Conta", icon: "paid", color: "" },
  { id: "pix", name: "PIX", icon: "attach_money", color: "#2dd4e0" },
];
