import React, { useState } from "react";
import { FunctionField } from "react-admin";
import Icon from "@material-ui/core/Icon";
import { Button } from "react-admin";
import QRCode from "qrcode";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
const url = process.env.REACT_APP_URL_VENDA_LINK;

export const RenderQRCode = (record) => {
  const [state, setState] = useState({ visible: false });
  const [image, setImage] = useState();

  let statusLabel = "QRCode";
  let icons = "qr_code_scanner";
  let color = "#536c94";
  let disabled = false;

  const show = () => {
    setState({ visible: true });
  };

  const hide = () => {
    setState({ visible: false });
  };

  const generateQR = async ({ id, sale_hash, sale_description }) => {
    const text = url + sale_hash;
    try {
      const qrCode = await QRCode.toDataURL(text);
      setImage(qrCode);
      console.log(
        `Venda: ${sale_description}, ID: ${id}, gerou o QRCode: ${qrCode}`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const RenderToggle = () => {
    return (
      <Rodal
        width={188}
        height={188}
        visible={state.visible}
        onClose={(event) => {
          event.stopPropagation();
          return hide();
        }}
      >
        <img
          src={image}
          onClick={(event) => {
            event.stopPropagation();
            return hide();
          }}
          alt="QRCode"
          width="180"
          height="180"
        ></img>
      </Rodal>
    );
  };
  return (
    <>
      <Button
        label={statusLabel}
        onClick={(event) => {
          event.stopPropagation();
          show();
          return generateQR(record);
        }}
        disabled={disabled}
      >
        <Icon style={{ color: color, marginBottom: 2 }}>{icons}</Icon>
      </Button>
      <RenderToggle />
    </>
  );
};

export const QRCodeMethodField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      return RenderQRCode(record);
    }}
  />
);
