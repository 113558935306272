import { documentNumberProvider } from "../providers/index";

const onChangeDocumentNumber = (element, form, setLoading) => {
  const documentNumber = element.target.value.replace(/\D+/g, "");

  if (documentNumber.length === 11 || documentNumber.length === 14) {
    setLoading(true);
    documentNumberProvider
      .getCpfCnpj(documentNumber)
      .then(({ data: { name, email, phone_number } }) => {
        setLoading(false);
        form.change("client_name", name);
        form.change("email", email);
        form.change("phone_number", phone_number);
      })
      .catch((response) => {
        setLoading(false);
        form.change("client_name", "");
        form.change("email", "");
        form.change("phone_number", "");
      });
  }
};

export default onChangeDocumentNumber;
