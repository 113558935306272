import React, { useState } from "react";
import { SaveButton, useCreate, useRedirect } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifySuccess = () => {
  return toast.success("Compra cadastrada com Sucesso", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: { backgroundColor: "green", opacity: 0.2 },
    autoClose: 3000,
  });
};

const notifyError = (error) => {
  return toast.error(
    error ? error.msg : "Valor abaixo de R$5,00 ou acima de R$500,00",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
      progressStyle: { backgroundColor: "red", opacity: 0.2 },
    }
  );
};

toast.configure({
  autoClose: 8000,
  draggable: false,
});

export const SaveSaleByLinkButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [create] = useCreate("sale_link");
  const redirectTo = useRedirect();
  const { basePath } = props;

  const handleSave = (values, redirect) => {
    let dadosClient = null;
    let dadosAddress = null;

    setLoading(true);
    let interruptor_client = document.getElementById("interruptor_client");
    let interruptorCheck = false;

    if (interruptor_client) {
      interruptorCheck = document.getElementById("interruptor_client").checked;
    }

    if (interruptorCheck) {
      switch (true) {
        case !values.document_number:
          setLoading(false);
          return notifyError({ msg: "Campo CPF/CNPJ obrigatório" });
        case !values.client_name:
          setLoading(false);
          return notifyError({ msg: "Campo Nome obrigatório" });
        case !values.email:
          setLoading(false);
          return notifyError({ msg: "Campo Email obrigatório" });
        case !values.zip_code:
          setLoading(false);
          return notifyError({ msg: "Campo CEP obrigatório" });
        case !values.address:
          setLoading(false);
          return notifyError({ msg: "Campo Logradouro obrigatório" });
        case !values.number:
          setLoading(false);
          return notifyError({ msg: "Campo Número obrigatório" });
        case !values.neighborhood:
          setLoading(false);
          return notifyError({ msg: "Campo Bairro obrigatório" });
        case !values.city:
          setLoading(false);
          return notifyError({ msg: "Campo Cidade obrigatório" });
        case !values.state:
          setLoading(false);
          return notifyError({ msg: "Campo Estado obrigatório" });

        default:
          break;
      }

      dadosClient = {
        document_number: values.document_number
          .replace(/[^\d,]+/g, "")
          .replace(".", "-"),
        email: values.email,
        name: values.client_name,
        phone_number: values.phone_number,
      };

      dadosAddress = {
        address: values.address,
        number: values.number,
        complement: values.complement,
        neighborhood: values.neighborhood,
        zip_code: values.zip_code,
        city: values.city,
        state: values.state,
      };
    }

    const {
      sale_description,
      amount,
      payment_type,
      installment_number,
    } = values;

    const formatValues = (value) => {
      let newValue = Math.round(
        Number(value.replace(/[^\d,]+/g, "").replace(",", ".")) * 100 +
          Number.EPSILON
      );
      return newValue / 100;
    };

    const interruptor = document.getElementById("interruptor_client");
    let change = true;

    if (interruptor) {
      if (interruptor.checked) {
        change = values.Dados ? true : false;
      }
    }

    const data = {
      sale_description: sale_description,
      amount: values.Doador
        ? 20
        : typeof amount === "string"
        ? formatValues(amount)
        : amount,
      payment_type,
      client_change: change,
      dadosClient,
      dadosAddress,
      installment_number: values.Doador ? 1 : installment_number,
      donate: values.Doador ? true : false,
    };

    create(
      {
        payload: { data },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          setLoading(false);
          notifySuccess();
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
        onFailure: (error) => {
          setLoading(false);
          notifyError(error.body);
        },
      }
    );
  };

  return (
    <SaveButton
      {...props}
      label={"Gerar Venda por Link"}
      onSave={handleSave}
      saving={loading}
      disabled={loading}
    />
  );
};
