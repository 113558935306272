import React, { useState } from "react";
import { SaveButton, useCreate, useRedirect } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifySuccess = () => {
  return toast.success("Compra cadastrada com Sucesso", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: { backgroundColor: "green", opacity: 0.2 },
    autoClose: 3000,
  });
};

const notifyError = (erro) => {
  return toast.error(
    erro ? erro : "Valor abaixo de R$ 5,00 ou acima de R$ 30.000,00",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
      progressStyle: { backgroundColor: "red", opacity: 0.2 },
    }
  );
};

toast.configure({
  autoClose: 8000,
  draggable: false,
});

export const SaveRecurringPurchaseButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [create] = useCreate("recurring_purchase");
  const redirectTo = useRedirect();
  const { basePath } = props;

  const handleSave = (values, redirect) => {
    let dates = [];
    let cardExpirySplit = [];
    let card = null;
    setLoading(true);

    const {
      plan_id,
      client_name,
      description,
      installment_number,
      parcel_value,
      amount,
      first_billing_date,
      payment_type,
      status,
      document_number,
      email,
      number,
      neighborhood,
      phone_number,
      state,
      complement,
      zip_code,
      address,
      city,
      Parcelamento,
      frequency,
      interval,
      card_cvc,
      card_expiry,
      card_name,
      card_number,
    } = values;

    for (let parcela = 1; parcela <= installment_number; parcela++) {
      dates.push(values[`date_${parcela}`]);
    }

    if (payment_type === "credit") {
      cardExpirySplit = card_expiry.split("/");
      card = {
        card_number,
        holder_name: card_name,
        expiration_month: cardExpirySplit[0],
        expiration_year: cardExpirySplit[1],
        security_code: card_cvc,
      };
    }

    const formatValues = (value) => {
      let newValue = Math.round(
        Number(value.replace(/[^\d,]+/g, "").replace(",", ".")) * 100 +
          Number.EPSILON
      );
      return newValue / 100;
    };

    const data = {
      plan_id: Number(plan_id),
      client_name,
      description,
      installment_number: Number(installment_number),
      parcel_value:
        typeof parcel_value === "string"
          ? formatValues(parcel_value)
          : parcel_value,
      amount: typeof amount === "string" ? formatValues(amount) : amount,
      first_billing_date,
      payment_type,
      status: Number(status),
      document_number: document_number
        .replace(/[^\d,]+/g, "")
        .replace(".", "-"),
      email,
      number: number.toString(),
      neighborhood,
      phone_number,
      state,
      complement,
      zip_code: zip_code.replace(/[^\d,]+/g, "").replace("-"),
      address,
      city,
      Parcelamento,
      frequency,
      interval,
      dates,
      card,
    };

    create(
      {
        payload: { data },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          setLoading(false);
          notifySuccess();
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
        onFailure: (error) => {
          setLoading(false);
          notifyError(error.body);
        },
      }
    );
  };

  return (
    <SaveButton
      {...props}
      label={"Gerar Compra Recorrente"}
      onSave={handleSave}
      saving={loading}
      disabled={loading}
    />
  );
};
