import React from "react";
import { FunctionField } from "react-admin";
import Icon from "@material-ui/core/Icon";
import { Button } from "react-admin";
import dataProvider from "../../../providers/dataProvider";
export const renderStatus = (record, refresh) => {
  let statusLabel = "";
  let icons = "cancel";
  let color = "#ccc";
  let disabled = true;

  const updatingStatus = async ({ id, status }) => {
    if (status === 1) {
      status = 3;
    }
    const params = {};
    params.data = {
      id,
      body: { status },
    };
    await dataProvider.updateStatus("recurring_purchase", params);
  };
  switch (record.status) {
    case 1:
      statusLabel = "Cancelar";
      color = "red";
      disabled = false;
      break;
    case 2:
      statusLabel = "Cancelar";
      break;
    case 3:
      statusLabel = "Cancelar";
      break;
    case 4:
      statusLabel = "Cancelar";
      break;
    case 5:
      statusLabel = "Cancelar";
      break;

    default:
      break;
  }

  return (
    <Button
      label={statusLabel}
      onClick={(event) => {
        event.stopPropagation();
        refresh();
        return updatingStatus(record);
      }}
      disabled={disabled}
    >
      <Icon style={{ color: color, marginBottom: 2 }}>{icons}</Icon>
    </Button>
  );
};

export const StatusMethodField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      return renderStatus(record);
    }}
  />
);
