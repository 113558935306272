import React, { useState } from "react";
import { Typography, Box, makeStyles, useMediaQuery } from "@material-ui/core";
import { useForm } from "react-final-form";
import { DateInput } from "react-admin-date-inputs";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { EventNote, AttachMoney, Receipt } from "@material-ui/icons";
import DadosParcel from "./DadosParcel";
import { CurrencyInputPlan } from "../inputs/index";
import { dataProvider } from "../../providers/index";
import { styles } from "../styles/index";

import {
  TextInput,
  LinearProgress,
  AutocompleteInput,
  useQueryWithStore,
} from "react-admin";

const useStyles = makeStyles(styles);
const requiredValidate = true;

const DadosPlanTab = () => {
  const form = useForm();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    themeMobile.inlineBlock = classes.inlineBlock;
    themeMobile.widthSmaller = "100%";
    themeMobile.widthBig = "100%";
  } else {
    themeMobile.inlineBlock = classes.inlineBlock_marginLeft;
    themeMobile.widthSmaller = 256;
    themeMobile.widthBig = 562;
  }

  const { loaded: loadedPlan, data: dataPlan } = useQueryWithStore({
    type: "getList",
    resource: "sale_plan",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {},
    },
  });

  const onChangePlans = (planId) => {
    if (planId) {
      setLoading(true);
      dataProvider
        .getOne("sale_plan", { id: Number(planId) })
        .then(
          ({
            data: {
              name,
              description,
              parcel_value,
              amount,
              installment_number,
              frequency,
              interval,
            },
          }) => {
            const firstBillingDate = document.getElementById(
              "first_billing_date"
            ).value;
            setLoading(false);
            form.change("description", description);
            form.change("parcel_value", parcel_value);
            form.change("amount", amount);
            form.change("installment_number", installment_number);
            form.change("frequency", frequency);
            form.change("interval", interval);

            if (firstBillingDate) {
              let init = firstBillingDate.split("/");
              let date = `${init[2]}-${init[1]}-${init[0]}`;
              setDadosParcelas({
                id: installment_number,
                parcel: installment_number,
                interval,
                frequency,
                parcel_value,
                date,
                isNewLoading: true,
              });
            }
          }
        )
        .catch((response) => {
          setLoading(false);
          form.change("description", "");
          form.change("parcel_value", "");
          form.change("amount", "");
          form.change("installment_number", "");
          form.change("frequency", "");
          form.change("interval", "");
        });
    }
  };

  const [Parcelamento, setDadosParcelas] = useState({});
  const [isParcelamento, setValidaParcelamento] = useState(false);

  return (
    <div>
      {loadedPlan && dataPlan ? (
        <div>
          <div
            style={{
              display: "inline-block",
              width: themeMobile.widthSmaller,
              paddingRight: 50,
            }}
          >
            <AutocompleteInput
              label="Plano"
              source="plan_id"
              options={{ fullWidth: true }}
              onChange={(value) => onChangePlans(value)}
              required={requiredValidate}
              choices={dataPlan}
              resettable
            />
          </div>
          <TextInput
            label="Descrição"
            source="description"
            multiline
            resettable
            formClassName={themeMobile.inlineBlock}
            style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
            required={requiredValidate}
          />
          <SeparatorInit />
          <div style={styles.div_align}>
            <EventNote />
            <Typography style={styles.typography_pagamento}>DATA</Typography>
          </div>
          <DateInput
            label="Data da Primeira Cobrança"
            source="first_billing_date"
            style={{ width: themeMobile.widthSmaller }}
            required={requiredValidate}
            providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
            options={{ format: "dd/MM/yyyy" }}
            initialvalue={new Date()}
            disablePast={true}
            onChange={(date) => {
              const id = document.getElementById("installment_number").value;
              const parcel = document.getElementById("installment_number")
                .value;
              const interval = document.getElementById("interval").value;
              const frequency = document.getElementById("frequency").value;
              const parcel_value = document.getElementById("parcel_value")
                .value;
              setDadosParcelas({
                id,
                parcel,
                interval,
                frequency,
                parcel_value,
                date,
              });
              setValidaParcelamento(true);
            }}
          />
          <div style={styles.div_align}>
            <AttachMoney />
            <Typography style={styles.typography}>VALORES</Typography>
          </div>
          <TextInput
            label="Valor por Parcela"
            source="parcel_value"
            formClassName={themeMobile.inlineBlock_split}
            style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
            InputProps={{ inputComponent: CurrencyInputPlan }}
            required={requiredValidate}
            placeholder={styles.placeholder_valores}
            disabled
          />
          <TextInput
            label="Número de Parcelas"
            source="installment_number"
            type="number"
            formClassName={themeMobile.inlineBlock_split}
            style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
            required={requiredValidate}
            disabled
          />
          <TextInput
            label="Valor Total das Parcelas"
            source="amount"
            formClassName={themeMobile.inlineBlock_split}
            style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
            InputProps={{ inputComponent: CurrencyInputPlan }}
            placeholder={styles.placeholder_valores}
            required={requiredValidate}
            disabled
          />
          {isParcelamento && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Receipt />
              <Typography style={styles.typography}>PARCELAS</Typography>
            </div>
          )}
          {isParcelamento && <DadosParcel products={Parcelamento} />}
        </div>
      ) : (
        <LinearProgress
          style={{
            width: themeMobile.widthSmaller,
            marginTop: 20,
            marginBotton: 40,
          }}
        />
      )}

      {isLoading && <LinearProgress style={styles.linear_progress} />}

      <TextInput
        label="Frequência"
        source="frequency"
        multiline
        resettable
        formClassName={themeMobile.inlineBlock}
        style={{
          display: "none",
          width: themeMobile.widthSmaller,
          paddingRight: 50,
        }}
      />
      <TextInput
        label="Intervalo"
        source="interval"
        multiline
        resettable
        formClassName={themeMobile.inlineBlock}
        style={{
          display: "none",
          width: themeMobile.widthSmaller,
          paddingRight: 50,
        }}
      />
    </div>
  );
};

const SeparatorInit = () => <Box pt="1em" />;

export default DadosPlanTab;
