import React from "react";
import Block from "@material-ui/icons/Block";
import Card from "@material-ui/core/Card";
import { useMediaQuery } from "@material-ui/core";

const PermissionBlock = ({ name }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const styles = {};

  if (
    isSmall &&
    (name === "Painel de Controle" ||
      name === "Transferências" ||
      name === "Planos de Assinatura" ||
      name === "Compra Recorrente" ||
      name === "Venda por Link" ||
      name === "Transferências Futuras")
  ) {
    styles.heightCard = 50;
    styles.marginTopIcon = 13;
  } else {
    styles.heightCard = "";
    styles.marginTopIcon = 4;
  }

  return (
    <Card
      style={{
        marginTop: 20,
        width: "100%",
        height: styles.heightCard,
        position: "absolute",
        background:
          "linear-gradient(230.6deg, #E06363 -20.9%, #E06363 35.4%, #E06363 100.28%)",
      }}
    >
      {!isSmall && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "2%",
            background: "rgba(130, 33, 80,0.8)",
            transform: "skew(-86.37deg, 71deg) translateX(1px)",
          }}
        ></div>
      )}
      <Block
        style={{
          marginTop: styles.marginTopIcon,
          marginLeft: 2,
          position: "relative",
          color: isSmall ? "#ffffff" : "#E06363",
        }}
      />
      <label
        style={{
          position: "absolute",
          marginLeft: 10,
          paddingTop: 7,
          color: "#ffffff",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: 14,
        }}
      >
        {" "}
        Sem privilégio de acesso a Rotina {name}
      </label>
    </Card>
  );
};

export { PermissionBlock };
