const calcParcel = (parcelNumber, parcelValue, form, isEdit) => {
  let valueParcel = "";
  if (parcelValue) {
    valueParcel = parcelValue.replace(/\D+/g, "");
    parcelNumber = document
      .getElementById("installment_number")
      .value.replace(/\D+/g, "");
  } else {
    valueParcel = document
      .getElementById("parcel_value")
      .value.replace(/\D+/g, "");
  }
  valueParcel /= 100;

  let valueCalc = parcelNumber * valueParcel;

  if (!isEdit) {
    valueCalc = valueCalc.toString().replace(".", ",");
  }
  form.change("amount", valueCalc);
};

export default calcParcel;
