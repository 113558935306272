import polyglotI18nProvider from "ra-i18n-polyglot";

import reactPtBrLang from "./react";

const messages = { pt: reactPtBrLang };
const i18nProvider = polyglotI18nProvider(locale => messages[locale], "pt", {
  allowMissing: true,
  onMissingKey: key => key
});

export default i18nProvider;
