import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { TextInput, LinearProgress } from "react-admin";
import { addressDataProvider } from "../../providers/index";
import { Separator } from "../index";
import { CepMaskedInput, NumberInput } from "../inputs/index";
import { styles } from "../styles/index";

const useStyles = makeStyles(styles);
const requiredValidate = true;

const DadosAddressTab = () => {
  const form = useForm();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  if (isSmall) {
    themeMobile.inlineBlock = classes.inlineBlock;
    themeMobile.widthSmaller = "100%";
    themeMobile.widthBig = "100%";
  } else {
    themeMobile.inlineBlock = classes.inlineBlock_marginLeft;
    themeMobile.widthSmaller = 256;
    themeMobile.widthBig = 562;
  }

  const onChangeZipCode = (element) => {
    const zipCode = element.target.value.replace(/\D+/g, "");

    if (zipCode.length === 8) {
      setLoading(true);
      addressDataProvider.getInfoCep(zipCode).then((infoCep) => {
        setLoading(false);

        const { cidade, estado, bairro, logradouro, complemento } = infoCep;

        form.change("city", cidade);
        form.change("state", estado);
        form.change("neighborhood", bairro);
        form.change("address", logradouro);
        form.change("complement", complemento);
      });
    }
  };

  return (
    <div>
      <div style={styles.div_align}>
        <LocationOnIcon />
        <Typography style={styles.typography}>ENDEREÇO</Typography>
      </div>
      <TextInput
        label="CEP"
        source="zip_code"
        formClassName={classes.inlineBlock}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        required={requiredValidate}
        onChange={(element) => onChangeZipCode(element)}
        InputProps={{ inputComponent: CepMaskedInput }}
      />
      <Separator />
      {isLoading && <LinearProgress style={styles.linear_progress} />}
      <TextInput
        label="Logradouro"
        source="address"
        formClassName={classes.inlineBlock}
        style={{ width: themeMobile.widthBig, paddingRight: 50 }}
        multiline
        required={requiredValidate}
      />
      <TextInput
        label="Número"
        source="number"
        formClassName={themeMobile.inlineBlock}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        InputProps={{ inputComponent: NumberInput }}
        required={requiredValidate}
        resettable
      />
      <Separator />
      <TextInput
        label="Complemento"
        source="complement"
        formClassName={themeMobile.inlineBlock}
        style={{ width: themeMobile.widthBig, paddingRight: 50 }}
        multiline
        resettable
      />
      <Separator />
      <TextInput
        label="Bairro"
        source="neighborhood"
        formClassName={classes.inlineBlock}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        required={requiredValidate}
      />
      <TextInput
        label="Cidade"
        source="city"
        formClassName={themeMobile.inlineBlock}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        required={requiredValidate}
      />
      <TextInput
        label="Estado"
        source="state"
        formClassName={themeMobile.inlineBlock}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        required={requiredValidate}
      />
    </div>
  );
};

export default DadosAddressTab;
