/**
 * Opções de Status da Transferencia
 */
export const statusList = [
  { id: "pending", name: "Pendente" },
  { id: "succeeded", name: "Pago" },
  { id: "canceled", name: "Cancelado" },
  { id: "failed", name: "Falhou" },
  { id: "paid", name: "Confirmado" },
  { id: "scheduled", name: "Agendado" }
];

/**
 * Opções de Tipo da Transferencia
 */
export const typeList = [
  { id: "payout_automatic", name: "Automático" },
  { id: "payout_manual", name: "Manual" }
];
