import React, { createContext, useReducer } from "react";
export const StoreContext = createContext({});

export const providerState = {};

/**
 * @param state
 * @param action
 * @returns {*}
 */
function reducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}

/**
 * @param children
 * @returns {*}
 * @constructor
 */
const AppStore = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, providerState);
  return (
    <StoreContext.Provider value={[state, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export default AppStore;
