import React from 'react';
import { LinearProgress } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery } from "@material-ui/core";

import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import VirtualizedTableSellerPlan from "./virtualizedTableSellerPlans";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 510,
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  table: { display: "inherit" },
  numberFormat: {
    background: "#fff",
    borderStyle: "none",
    display: "flex",
  },
  spanFormat: {
    opacity: 0.8,
    display: "flex",
  },
  rowTitle: {
    background: "#822150",
    display: "flex",
  },
  rowContent: {
    background: "#fff",
    display: "flex",
  },
  columnTitle: {
    width: "100%",
    textAlign: "center",
  },
  titleColor: {
    color: "#fff",
  },
  columnContent: {
    width: "50%",
  },
}));

export default function ControlledExpansionPanels({ sellerPlan, error }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const newClass = {};

  if (isSmall) {
    newClass.widthLoading = '100%';
    newClass.maxHeight = 30;
    newClass.fontSizeTitle = "100%";
  } else {
    newClass.widthLoading = 502;
    newClass.maxHeight = 40;
    newClass.fontSizeTitle = "150%";
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const status = {
    true: 'Ativo',
    false: 'Inativo',
  };

  const colorStatus = {
    true: 'rgba(50, 168, 82, 0.7)',
    false: 'rgba(235, 64, 52, 0.7)',
  };

  if (sellerPlan && !error) {
    return (
      <div className={classes.table}>
        <TableRow className={classes.rowTitle}>
          <TableCell
            className={classes.columnTitle}
            style={{ height: newClass.maxHeight, border: "none" }}
          >
            <span
              className={classes.titleColor}
              style={{ fontSize: newClass.fontSizeTitle }}
            >
              {sellerPlan.plan_name}
            </span>
            {!isSmall && <div style={{ position: 'absolute', marginLeft: -20, backgroundColor: colorStatus[sellerPlan.is_active], width: 518 }}>
              <span
                className={classes.titleColor}
                style={{ fontSize: '100%' }}
              >
                {status[sellerPlan.is_active]}
              </span>
            </div>}
            {isSmall && <div style={{ marginLeft: -18, backgroundColor: colorStatus[sellerPlan.is_active], width: '112%' }}>
              <span
                className={classes.titleColor}
                style={{ fontSize: '100%' }}
              >
                {status[sellerPlan.is_active]}
              </span>
            </div>}
          </TableCell>
        </TableRow>

        <ExpansionPanel expanded={expanded === 'boleto'} onChange={handleChange('boleto')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="boleto-content"
            id="boleto-header"
          >
            <Typography className={classes.heading}>Boleto</Typography>
            <Typography className={classes.secondaryHeading}>Recebimento em 1 dia</Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <VirtualizedTableSellerPlan record={sellerPlan.boleto} payment_type={'boleto'} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel expanded={expanded === 'credit'} onChange={handleChange('credit')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="credit-content"
            id="credit-header"
          >
            <Typography className={classes.heading}>Crédito</Typography>
            <Typography className={classes.secondaryHeading}>Venda Online</Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <VirtualizedTableSellerPlan record={sellerPlan} payment_type={'credit'} credit_type={'online'} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {sellerPlan.have_physics && <ExpansionPanel expanded={expanded === 'credit_physics'} onChange={handleChange('credit_physics')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="credit_physics-content"
            id="credit_physics-header"
          >
            <Typography className={classes.heading}>Crédito</Typography>
            <Typography className={classes.secondaryHeading}>Venda Física</Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <VirtualizedTableSellerPlan record={sellerPlan} payment_type={'credit'} credit_type={'physics'} />
          </ExpansionPanelDetails>
        </ExpansionPanel>}

        <ExpansionPanel expanded={expanded === 'debit'} onChange={handleChange('debit')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="debit-content"
            id="debit-header"
          >
            <Typography className={classes.heading}>Débito</Typography>
            <Typography className={classes.secondaryHeading}>Recebimento em 1 dia</Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <VirtualizedTableSellerPlan record={sellerPlan} payment_type={'debit'} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div >
    );
  }

  const Loading = () => (
    <div style={{ width: newClass.widthLoading }}>
      <LinearProgress
        style={{
          width: newClass.widthLoading,
          position: "flex",
          display: "flex",
        }}
      />
    </div>
  );

  if (error) {
    return <div style={{ textAlign: 'center', marginBottom: 10, marginTop: 10 }}>
      <label
        style={{
          color: "#ffffff",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: 14,
        }}
      >
        {error}
      </label>
    </div>
  }

  return <Loading />;
}