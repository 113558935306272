import {
  isSaturday,
  isSunday,
  addDays,
  addWeeks,
  addMonths,
  addYears,
} from "date-fns";

const formatDiaria = (data_inicial, intervalo, numero_parcela) => {
  let newDate = addDays(data_inicial, intervalo * (numero_parcela - 1));
  if (isSaturday(newDate)) {
    newDate = addDays(newDate, 2);
  }
  if (isSunday(newDate)) {
    newDate = addDays(newDate, 1);
  }
  return newDate;
};

const formatSemanal = (data_inicial, intervalo, numero_parcela) => {
  let newDate = addWeeks(data_inicial, intervalo * (numero_parcela - 1));
  if (isSaturday(newDate)) {
    newDate = addDays(newDate, 2);
  }
  if (isSunday(newDate)) {
    newDate = addDays(newDate, 1);
  }
  return newDate;
};

const formatMensal = (data_inicial, intervalo, numero_parcela) => {
  let newDate = addMonths(data_inicial, intervalo * (numero_parcela - 1));
  if (isSaturday(newDate)) {
    newDate = addDays(newDate, 2);
  }
  if (isSunday(newDate)) {
    newDate = addDays(newDate, 1);
  }
  return newDate;
};

const formatAnual = (data_inicial, intervalo, numero_parcela) => {
  let newDate = addYears(data_inicial, intervalo * (numero_parcela - 1));
  if (isSaturday(newDate)) {
    newDate = addDays(newDate, 2);
  }
  if (isSunday(newDate)) {
    newDate = addDays(newDate, 1);
  }
  return newDate;
};

const formatDates = (data_inicial, numero_parcela, frequencia, intervalo) => {
  switch (frequencia) {
    case 1:
      return formatDiaria(data_inicial, intervalo, numero_parcela);
    case 2:
      return formatSemanal(data_inicial, intervalo, numero_parcela);
    case 3:
      return formatMensal(data_inicial, intervalo, numero_parcela);
    case 4:
      return formatAnual(data_inicial, intervalo, numero_parcela);

    default:
      break;
  }
};

export default formatDates;
