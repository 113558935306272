/**
 * Opções de Status da Transação
 */
export const statusList = [
  { id: 1, name: "Ativa" },
  { id: 2, name: "Finalizada", disabled: true },
  { id: 3, name: "Cancelada", disabled: true },
];

/**
 * Opções de Tipo de Pagamento
 */
export const paymentTypeList = [
  { id: "boleto", name: "Boleto" },
  { id: "credit", name: "Crédito" },
];
