import React from "react";
import {
  List,
  Datagrid,
  TextField,
  usePermissions,
  DeleteButton
} from "react-admin";

import {
  useMediaQuery,
} from "@material-ui/core";
import { PermissionBlock } from "../../services/permissions/permissionBlock";
import ListActions from "./components/bankAccountListActions";

export const BankAccountList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "bank_account");

    if (found !== "bank_account") {
      return <PermissionBlock name={"Conta Bancária"} />;
    }
  }

  return (
    <List
      {...props}
      title="Listar Planos"
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      {isSmall ?
        <Datagrid >
          <TextField source="bank_name" label="Nome do Banco" sortable={false} />
          <TextField source="routing_number" label="Agência" sortable={false} />
          <TextField source="account_number" label="N° da Conta" sortable={false} />
          <DeleteButton />
        </Datagrid>
        :
        <Datagrid >
          <TextField source="id" label="ID" sortable={false} />
          <TextField source="holder_name" label="Nome do Holder" sortable={false} />
          <TextField source="taxpayer_id" label="CPF/CNPJ" sortable={false} />
          <TextField source="bank_name" label="Nome do Banco" sortable={false} />
          <TextField source="routing_number" label="Agência" sortable={false} />
          <TextField source="account_number" label="N° da Conta" sortable={false} />
          <DeleteButton />
        </Datagrid>
      }
    </List>
  );
};
