import React from "react";
import { useForm } from "react-final-form";
import { useMediaQuery, makeStyles, Typography } from "@material-ui/core";
import { calcParcel, formatValues } from "../index";
import { CurrencyInputEdit, NumberInput, CurrencyInput } from "../inputs/index";
import { TextInput } from "react-admin";
import AttachMoney from "@material-ui/icons/AttachMoney";
import { styles } from "../styles/index";

const useStyles = makeStyles(styles);
const requiredValidate = true;

const DadosValues = ({ isEdit }) => {
  const form = useForm();
  const classes = useStyles();
  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  if (isSmall) {
    themeMobile.inlineBlock = classes.inlineBlock;
    themeMobile.widthSmaller = "100%";
    themeMobile.widthBig = "100%";
  } else {
    themeMobile.inlineBlock = classes.inlineBlock_marginLeft;
    themeMobile.widthSmaller = 256;
    themeMobile.widthBig = 562;
  }

  return (
    <div>
      <div style={styles.div_align}>
        <AttachMoney />
        <Typography style={styles.typography}>VALORES</Typography>
      </div>
      <TextInput
        label="Valor por Parcela"
        source="parcel_value"
        formClassName={themeMobile.inlineBlock_split}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        InputProps={{
          inputComponent: isEdit ? CurrencyInputEdit : CurrencyInput,
        }}
        parse={(value) => {
          calcParcel(1, value, form, isEdit);
          if (isEdit) {
            return formatValues(value);
          }
          return value;
        }}
        required={requiredValidate}
        placeholder={styles.placeholder_valores}
      />
      <TextInput
        label="Número de Parcelas"
        source="installment_number"
        formClassName={themeMobile.inlineBlock_split}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        parse={(value) => {
          calcParcel(value, false, form, isEdit);
          return value;
        }}
        InputProps={{ inputComponent: NumberInput }}
        required={requiredValidate}
        resettable
      />
      <TextInput
        label="Valor Total das Parcelas"
        source="amount"
        formClassName={themeMobile.inlineBlock_split}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        InputProps={{
          inputComponent: isEdit ? CurrencyInputEdit : CurrencyInput,
        }}
        placeholder={styles.placeholder_valores}
        required={requiredValidate}
      />
    </div>
  );
};

export default DadosValues;
