import React from "react";
import NumberFormat from "react-number-format";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useQueryWithStore, LinearProgress } from "react-admin";

const useStyles = makeStyles({
  table: { display: "inherit" },
  numberFormat: {
    background: "#fff",
    borderStyle: "none",
    display: "flex",
  },
  spanFormat: {
    opacity: 0.8,
    display: "flex",
  },
  rowTitle: {
    background: "#822150",
    display: "flex",
  },
  rowContent: {
    background: "#fff",
    display: "flex",
  },
  columnTitle: {
    width: "100%",
    textAlign: "center",
  },
  titleColor: {
    color: "#fff",
  },
  columnContent: {
    width: "50%",
  },
});

export const AccountBalance = () => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const newClass = {};

  const { error, loaded, data } = useQueryWithStore({
    type: "getAccountBalance",
  });

  if (isSmall) {
    newClass.fontSizeNumber = "0.9em";
    newClass.fontSizeSpan = "80%";
    newClass.maxHeight = 30;
    newClass.fontSizeTitle = "170%";
    newClass.widthLoading = "100%";
  } else {
    newClass.fontSizeNumber = 20;
    newClass.fontSizeSpan = "100%";
    newClass.maxHeight = 50;
    newClass.fontSizeTitle = "200%";
    newClass.widthLoading = 502;
  }

  const Loading = () => (
    <div className={classes.table}>
      <LinearProgress
        style={{
          width: newClass.widthLoading,
          position: "flex",
          display: "flex",
        }}
      />
    </div>
  );

  if (error) {
    return <Loading />;
  } else if (!loaded) {
    return <Loading />;
  } else if (data) {
    return (
      <table className={classes.table}>
        <TableRow className={classes.rowTitle}>
          <TableCell
            className={classes.columnTitle}
            style={{ maxHeight: newClass.maxHeight, border: "none" }}
          >
            <span
              className={classes.titleColor}
              style={{ fontSize: newClass.fontSizeTitle }}
            >
              Saldo da Conta
            </span>
          </TableCell>
        </TableRow>
        <TableRow className={classes.rowContent}>
          <TableCell
            className={classes.columnContent}
            style={{ paddingLeft: 16, paddingRight: 0 }}
          >
            <span className={classes.spanFormat} style={{ fontSize: "90%" }}>
              Saldo Atual:
            </span>
            <NumberFormat
              className={classes.numberFormat}
              style={{ fontSize: newClass.fontSizeNumber }}
              allowedDecimalSeparators={[",", "."]}
              thousandSeparator="."
              decimalSeparator=","
              prefix={"R$ "}
              value={
                data.current_balance
                  ? data.current_balance.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0
              }
              disabled
            />
          </TableCell>
          <TableCell
            className={classes.columnContent}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <span className={classes.spanFormat} style={{ fontSize: "90%" }}>
              Saldo a Receber:
            </span>
            <NumberFormat
              className={classes.numberFormat}
              style={{ fontSize: newClass.fontSizeNumber }}
              allowedDecimalSeparators={[",", "."]}
              thousandSeparator="."
              decimalSeparator=","
              prefix={"R$ "}
              value={
                data.account_balance
                  ? data.account_balance.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0
              }
              disabled
            />
          </TableCell>
        </TableRow>
      </table>
    );
  } else {
    return <Loading />;
  }
};
