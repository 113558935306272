import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  FunctionField,
} from "react-admin";

import VirtualizedTable from "./virtualizedTable";
const url = process.env.REACT_APP_URL_VENDA_LINK;

const SalePlanTitle = () => <span>Detalhes da Venda por Link</span>;

export const SaleByLinkShow = (props) => {
  return (
    <Show title={<SalePlanTitle />} {...props}>
      <SimpleShowLayout>
        <NumberField
          source="amount"
          label="Valor da venda"
          options={{ style: "currency", currency: "BRL" }}
        />
        <TextField source="sale_description" label="Descrição da Venda" />
        <FunctionField
          label="Link de Pagamento"
          render={(record) => `${url}${record.sale_hash}`}
        />
        <VirtualizedTable isSaleLink={true} />
      </SimpleShowLayout>
    </Show>
  );
};
