export const validateCreation = (values) => {
  const errors = {};
  const document = values.document
    ? values.document.replace(/[^\d,]+/g, "")
    : "";

  if (!(document && (document.length === 11 || document.length === 14))) {
    errors.document = ["CPF/CNPJ obrigatório"];
  }
  const newAmount = values.amount
    ? Number(values.amount.replace(/[^\d,]+/g, "").replace(",", "."))
    : 0;
  if (!newAmount) {
    errors.amount = ["Valor obrigatório"];
  } else if (newAmount < 10) {
    errors.amount = ["Valor mínimo R$ 10,00"];
  }

  if (!values.name) {
    errors.name = ["Nome obrigatório"];
  }
  if (!values.bank_code) {
    errors.bank_code = ["Código do banco obrigatório"];
  }
  if (values.bank_code && values.bank_code.length < 3) {
    errors.bank_code = ["Mínimo 3 digitos obrigatórios"];
  }
  if (!values.routing_number) {
    errors.routing_number = ["Número da agência obrigatório"];
  }
  if (!values.account_number) {
    errors.account_number = ["Número da Conta obrigatório"];
  }
  if (!values.account_check_digit) {
    errors.account_check_digit = ["Digito verificador obrigatório"];
  }

  return errors;
};
