import React from "react";
import Icon from "@material-ui/core/Icon";

const TypeField = ({ record }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Icon style={{ color: record.color }}>{record.icon}</Icon>
      <p style={{ marginLeft: 5 }}>{record.name}</p>
    </div>
  );
};

export default TypeField;
