import React from "react";
import { Box, Typography, useMediaQuery, makeStyles } from "@material-ui/core";
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";
import { Assignment, EventNote } from "@material-ui/icons";
import { validateEdit } from "./validations/index";
import { frequencyList } from "./common";
import { NumberInput } from "../../services/inputs/index";
import { DadosValues } from "../../services/dados/index";
import { styles, loadThemeMobile } from "../../services/styles/index";

const useStyles = makeStyles(styles);
const requiredValidate = true;

export const SalePlanEdit = (props) => {
  const classes = useStyles();
  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  loadThemeMobile(isSmall, themeMobile, classes);

  return (
    <Edit {...props}>
      <SimpleForm validate={validateEdit}>
        <div style={styles.div_align}>
          <Assignment />
          <Typography style={styles.typography}>DESCRITIVOS</Typography>
        </div>

        <TextInput
          label="Nome do Plano"
          source="name"
          resettable
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <TextInput
          label="Descrição"
          source="description"
          multiline
          resettable
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <SeparatorInit />
        <div style={styles.div_align}>
          <EventNote />
          <Typography style={styles.typography_pagamento}>PERÍODO</Typography>
        </div>
        <SelectInput
          label="Frequência"
          source="frequency"
          choices={frequencyList}
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <TextInput
          label="Intervalo"
          source="interval"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          InputProps={{ inputComponent: NumberInput }}
          required={requiredValidate}
          resettable
        />
        <SeparatorInit />
        <DadosValues isEdit={true} />
      </SimpleForm>
    </Edit>
  );
};

const SeparatorInit = () => <Box pt="1em" />;
