export const validateCreation = (values) => {
  const errors = {};

  if (!values.plan_id) {
    errors.plan_id = ["Plano obrigatório"];
  }
  if (!values.email) {
    errors.email = ["Email obrigatório"];
  }
  if (!values.zip_code) {
    errors.zip_code = ["CEP obrigatório"];
  }
  if (!values.city) {
    errors.city = ["Cidade obrigatória"];
  }
  if (!values.state) {
    errors.state = ["Estado obrigatório"];
  }
  if (!values.neighborhood) {
    errors.neighborhood = ["Bairro obrigatório"];
  }
  if (!values.number) {
    errors.number = ["Número obrigatório"];
  }
  if (!values.address) {
    errors.address = ["Logradouro obrigatório"];
  }
  if (!values.document_number) {
    errors.document_number = ["CPF/CNPJ obrigatório"];
  }
  if (!values.client_name) {
    errors.client_name = ["Nome do Cliente obrigatório"];
  }
  if (!values.description) {
    errors.description = ["Descrição obrigatória"];
  }
  if (!values.installment_number) {
    errors.installment_number = ["Número de Parcelas obrigatório"];
  }
  if (!values.parcel_value) {
    errors.parcel_value = ["Valor por Parcela obrigatório"];
  }
  if (!values.amount) {
    errors.amount = ["Valor Total obrigatório"];
  }
  if (!values.first_billing_date) {
    errors.first_billing_date = ["Data da Primeira Cobrança obrigatória"];
  }

  if (!values.payment_type) {
    errors.payment_type = ["Tipo do Pagamento obrigatório"];
  }

  if (values.payment_type === "credit") {
    if (!values.card_number) {
      errors.card_number = ["Número do Cartão obrigatório"];
    }
    if (!values.card_name) {
      errors.card_name = ["Nome Titular obrigatório"];
    }
    if (!values.card_cvc) {
      errors.card_cvc = ["CVC obrigatório"];
    }
    if (!values.card_expiry) {
      errors.card_expiry = ["Mês/Ano obrigatórios"];
    }
    if (values.card_expiry && values.card_expiry.length <= 6) {
      errors.card_expiry = ["Mês/Ano incompletos"];
    }
  }

  return errors;
};
