import React from "react";
import {
  Filter,
  List,
  Datagrid,
  SimpleList,
  TextField,
  NumberField,
  DateField,
  SelectField,
  FunctionField,
  SelectInput,
  usePermissions,
} from "react-admin";

import { DateInput } from "react-admin-date-inputs";
import { startOfDay, endOfDay, parseISO, formatISO, subDays } from "date-fns";
import { statusList, typeList } from "./common";
import { useMediaQuery } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import ListActions from "./components/transferListActions";
import { transfersExport } from "../exports/index";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const TransfersFilter = (props) => (
  <Filter {...props}>
    <DateInput
      label="Data Início"
      source="created_from"
      providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
      options={{ format: "dd/MM/yyyy" }}
      initialvalue={new Date()}
      parse={(value) => (value ? formatISO(parseISO(value)) : null)}
      style={{ marginTop: 40 }}
      alwaysOn
      clearable
    />
    <DateInput
      label="Data Fim"
      source="created_to"
      providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
      options={{ format: "dd/MM/yyyy" }}
      parse={(value) => (value ? formatISO(parseISO(value)) : null)}
      alwaysOn
      clearable
    />
    <SelectInput label="Status" source="status" choices={statusList} />
  </Filter>
);

export const TransfersList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "transfers");

    if (found !== "transfers") {
      return <PermissionBlock name={"Transferências"} />;
    }
  }

  return (
    <List
      {...props}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<TransfersFilter />}
      filterDefaultValues={{
        created_from: formatISO(startOfDay(subDays(new Date(), 30))),
        created_to: formatISO(endOfDay(new Date())),
      }}
      title="Listar Transferências"
      exporter={transfersExport}
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.description}
          secondaryText={(record) =>
            new Intl.NumberFormat("pt-br", {
              style: "currency",
              currency: "BRL",
            }).format(record.amount)
          }
          tertiaryText={(record) =>
            new Date(record.created_at).toLocaleDateString()
          }
          linkType="show"
        />
      ) : (
        <Datagrid rowClick="show">
          <DateField
            source="created_at"
            label="Data do Pagamento"
            showTime={true}
          />
          <SelectField source="status" choices={statusList} sortable={false} />
          <SelectField
            source="type"
            label="Tipo de Pagamento"
            choices={typeList}
            sortable={false}
          />
          <TextField source="description" label="Descrição" sortable={false} />
          <FunctionField
            label="Meio de Recebimento"
            render={({ bank_account }) =>
              bank_account ? (
                <div>
                  <b>{bank_account.bank_name}</b>
                  <br />
                  Ag: {bank_account.routing_number} Conta:{" "}
                  {bank_account.account_number}
                </div>
              ) : (
                ""
              )
            }
          />
          <NumberField
            source="amount"
            label="Valor"
            options={{ style: "currency", currency: "BRL" }}
            sortable={false}
          />
        </Datagrid>
      )}
    </List>
  );
};
