import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

function CurrencyInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={createNumberMask({
        prefix: "R$ ",
        thousandsSeparatorSymbol: ".",
        decimalSymbol: ",",
        decimalLimit: 2,
        requireDecimal: true,
        allowDecimal: true,
      })}
      pipe={(value, config) => {
        if (config.rawValue) {
          const numberValue = Number(
            config.rawValue
              .replace(/[^\d,]+/g, "")
              .replace(",,", ",")
              .replace(",", ".")
          );

          if (!Number.isNaN(numberValue)) {
            return new Intl.NumberFormat("pt-br", {
              style: "currency",
              currency: "BRL",
            }).format(numberValue);
          }
        }
        return value;
      }}
      placeholderChar={"\u2000"}
    />
  );
}

CurrencyInput.propTypes = { inputRef: PropTypes.func.isRequired };

export default CurrencyInput;
