import React, { useState } from "react";
import { SaveButton, useCreate, useRedirect } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifySuccess = () => {
  return toast.success("Compra cadastrada com Sucesso", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: { backgroundColor: "green", opacity: 0.2 },
    autoClose: 3000,
  });
};

const notifyError = (error) => {
  return toast.error(
    error ? error.msg : "Valor abaixo de R$5,00 ou acima de R$500,00",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
      progressStyle: { backgroundColor: "red", opacity: 0.2 },
    }
  );
};

toast.configure({
  autoClose: 8000,
  draggable: false,
});

const formatValues = (value) => {
  let newValue = Math.round(
    Number(value.replace(/[^\d,]+/g, "").replace(",", ".")) * 100 +
      Number.EPSILON
  );
  return newValue / 100;
};

export const SaveDonateButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [create] = useCreate("donate");
  const redirectTo = useRedirect();
  const { basePath } = props;

  const handleSave = (values, redirect) => {
    setLoading(true);

    const {
      sale_description,
      payment_type,
      value_rule_01,
      value_rule_02,
      value_rule_03,
      value_rule_04,
    } = values;

    let change = true;
    const data = {
      sale_description: sale_description,
      amount: formatValues(value_rule_01),
      payment_type,
      client_change: change,
      installment_number: 1,
      value_rules: [
        formatValues(value_rule_01),
        formatValues(value_rule_02),
        formatValues(value_rule_03),
        formatValues(value_rule_04),
      ],
    };

    create(
      {
        payload: { data },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          setLoading(false);
          notifySuccess();
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
        onFailure: (error) => {
          setLoading(false);
          notifyError(error.body);
        },
      }
    );
  };

  return (
    <SaveButton
      {...props}
      label={"Gerar Doação por Link"}
      onSave={handleSave}
      saving={loading}
      disabled={loading}
    />
  );
};
