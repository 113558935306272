import React from "react";

import { Show, SimpleShowLayout, TextField, NumberField } from "react-admin";

const SalePlanTitle = () => <span>Detalhes do Plano</span>;

export const SalePlanShow = (props) => {
  return (
    <Show title={<SalePlanTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" label="Nome" />
        <NumberField
          source="amount"
          label="Valor total do Plano"
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField
          source="parcel_value"
          label="Valor por Parcela"
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField source="installment_number" label="Número de Parcelas" />
      </SimpleShowLayout>
    </Show>
  );
};
