const styles = {
  inlineBlock: { display: "inline-block" },
  inlineBlock_marginLeft: { display: "inline-block", marginLeft: 32 },
  typography: { paddingLeft: 5, paddingTop: 5 },
  typography_pagamento: { paddingLeft: 5, paddingTop: 2 },
  div_align: { display: "flex", alignItems: "center" },
  linear_progress: { width: 256, marginTop: -15, position: "absolute" },
  placeholder_valores: "Valor mínimo: R$ 5,00",
};

export default styles;
