import React from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  makeStyles,
  Paper,
} from "@material-ui/core";

import {
  AttachMoney,
  SupervisorAccount,
  AccountBalanceWallet,
  DoubleArrow,
} from "@material-ui/icons";

import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
} from "react-admin";

import { SaveTedButton } from "./components/SaveTedButton";
import { validateCreation } from "./validations/index";
import {
  NewCurrencyInput,
  DocumentNumberMaskedInput,
} from "../../services/inputs/index";
import { styles, loadThemeMobile } from "../../services/styles/index";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const useStyles = makeStyles(styles);
const requiredValidate = true;

const TimeNotice = ({ isSmall }) => {
  return (
    <Paper
      elevation={5}
      style={{
        width: isSmall ? "95%" : "100%",
        marginBottom: 20,
        padding: 10,
        background: "#376cbd",
        color: "#ffffff",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 16,
      }}
    >
      <label>
        <DoubleArrow style={{ margin: -6, padding: 0, marginRight: 2 }} />{" "}
        Horários para a realização de TED são entre{" "}
        <label style={{ color: "black" }}>(09:00 - </label>
        <label style={{ color: "black" }}>16:30)</label>
      </label>
    </Paper>
  );
};

export const TedCreate = (props) => {
  const classes = useStyles();

  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  loadThemeMobile(isSmall, themeMobile, classes);

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "ted");

    if (found !== "ted") {
      return <PermissionBlock name={"Novo TED"} />;
    }
  }

  return (
    <Create title="Novo TED" {...props}>
      <SimpleForm validate={validateCreation} toolbar={<CustomToolbar />}>
        <TimeNotice isSmall={isSmall} />
        <div style={styles.div_align}>
          <AttachMoney />
          <Typography style={styles.typography}>VALOR</Typography>
        </div>
        <TextInput
          label="Valor"
          source="amount"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          InputProps={{ inputComponent: NewCurrencyInput }}
          placeholder={"Valor mínimo R$ 10,00"}
          autoComplete={"off"}
        />

        <div style={styles.div_align}>
          <SupervisorAccount />
          <Typography style={styles.typography}>DADOS</Typography>
        </div>
        <TextInput
          label="Nome"
          source="name"
          multiline
          resettable
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <TextInput
          label="CPF/CNPJ"
          source="document"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          InputProps={{ inputComponent: DocumentNumberMaskedInput }}
          required={requiredValidate}
        />
        <TextInput
          label="Descrição"
          source="description"
          multiline
          resettable
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        />
        <div style={styles.div_align}>
          <AccountBalanceWallet />
          <Typography style={styles.typography}>CONTA DESTINO</Typography>
        </div>
        <TextInput
          label="Código do Banco"
          source="bank_code"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <SeparatorInit />
        <TextInput
          label="Número da agência"
          source="routing_number"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <TextInput
          label="Digito verificador da agência"
          source="routing_check_digit"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          placeholder={"01"}
        />
        <SeparatorInit />
        <TextInput
          label="Número da conta de destino"
          source="account_number"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <TextInput
          label="Digito verificador da conta"
          source="account_check_digit"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          placeholder={"01"}
          required={requiredValidate}
        />
      </SimpleForm>
    </Create>
  );
};

const SeparatorInit = () => <Box pt="1em" />;
const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveTedButton />
  </Toolbar>
);
