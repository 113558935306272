import { fetchUtils } from "react-admin";

const httpClient = fetchUtils.fetchJson;

const addressDataProvider = {
  getInfoCep: cep =>
    httpClient(`https://api.postmon.com.br/v1/cep/${cep}`)
      .then(({ json }) => json || {})
      .catch(() => ({}))
};

export default addressDataProvider;
