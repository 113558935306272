import React from "react";
import { SimpleForm } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import Assignment from "@material-ui/icons/Assignment";
import EventNote from "@material-ui/icons/EventNote";
import { Create, TextInput, SelectInput, Toolbar } from "react-admin";
import { SaveNewSalePlanButton } from "./components/index";
import { NumberInput } from "../../services/inputs/index";
import { DadosValues } from "../../services/dados/index";
import { validateCreation } from "./validations/index";
import { frequencyList } from "./common";
import { styles, loadThemeMobile } from "../../services/styles/index";

const useStyles = makeStyles(styles);
const requiredValidate = true;

export const SalePlanCreate = (props) => {
  const classes = useStyles();
  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  loadThemeMobile(isSmall, themeMobile, classes);

  return (
    <Create title="Novo Plano" {...props}>
      <SimpleForm validate={validateCreation} toolbar={<CustomToolbar />}>
        <div style={styles.div_align}>
          <Assignment />
          <Typography style={styles.typography}>DESCRITIVOS</Typography>
        </div>

        <TextInput
          label="Nome do Plano"
          source="name"
          resettable
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <TextInput
          label="Descrição"
          source="description"
          multiline
          resettable
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <SeparatorInit />
        <div style={styles.div_align}>
          <EventNote />
          <Typography style={styles.typography_pagamento}>PERÍODO</Typography>
        </div>
        <SelectInput
          label="Frequência"
          source="frequency"
          choices={frequencyList}
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <TextInput
          label="Intervalo"
          source="interval"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          InputProps={{ inputComponent: NumberInput }}
          resettable
        />
        <SeparatorInit />
        <DadosValues isEdit={false} />
      </SimpleForm>
    </Create>
  );
};

const SeparatorInit = () => <Box pt="1em" />;
const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveNewSalePlanButton />
  </Toolbar>
);
