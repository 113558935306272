import { fetchUtils } from "react-admin";
import { url as apiUrl, getDefaultHeaders } from "../config/api";

const httpClient = fetchUtils.fetchJson;

const transferTransactionsProvider = {
  getTransferTransactions: async (transferId) => {
    const { json = [] } = await httpClient(
      `${apiUrl}/transfers/${transferId}/transactions`,
      {
        headers: getDefaultHeaders(),
        credentials: "include",
      }
    );

    return {
      data: json || [],
    };
  },
};

export default transferTransactionsProvider;
