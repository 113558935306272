import { yellow } from "@material-ui/core/colors";
/**
 * Opções de Status da Transação
 */
export const statusList = [
  { id: "succeeded", icon: "add_circle", color: "green", name: "Aprovada" },
  { id: "canceled", icon: "remove_circle", color: "red", name: "Cancelada" },
  { id: "pending", icon: "watch_later", color: yellow[700], name: "Pendente" },
  { id: "failed", icon: "cancel", color: "red", name: "Falhou" },
  {
    id: "pre_authorized",
    icon: "check_circle_outline",
    color: "green",
    name: "Pré-autorizada",
  },
  { id: "reversed", icon: "cached", color: "", name: "Revertida" },
  { id: "refunded", icon: "360", color: "", name: "Estornada" },
  { id: "dispute", icon: "sync_problem", color: "", name: "Em Disputa" },
  { id: "charged_back", icon: "360", color: "", name: "Charged Back" },
];

/**
 * Opções de Tipo de Pagamento
 */
export const paymentTypeList = [
  { id: "credit", name: "Crédito" },
  { id: "debit", name: "Débito" },
  { id: "boleto", name: "Boleto" },
  { id: "pix", name: "PIX" },
];
