import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configuration from "../config/cognito";

const notifyInfo = () => {
  return toast.info("Email com código de recuperação enviado", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: { backgroundColor: "#e4a7fa", opacity: 0.2 },
    autoClose: 3000,
  });
};

const notifyError = (error) => {
  return toast.error(
    error
      ? error.msg
      : "Muitas solicitações de código, por favor tente mais tarde",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
      progressStyle: { backgroundColor: "#f56262", opacity: 0.2 },
    }
  );
};

toast.configure({
  autoClose: 8000,
  draggable: false,
});

const getCognitoUserPool = async () => {
  return new CognitoUserPool(configuration);
};

const getCognitoUser = async (email) => {
  return new CognitoUser({
    Username: email,
    Pool: await getCognitoUserPool(),
  });
};

const forgotPasswordProvider = async (email, setForgotPass, setDisplay) => {
  if (email) {
    const cognitoUser = await getCognitoUser(email)
      .then((result) => {
        if (result) {
          return Promise.resolve(result);
        }
      })
      .catch((error) => {
        return notifyError();
      });

    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        console.log("CodeDeliveryData from forgotPassword: " + data);
        return notifyInfo();
      },
      onFailure: function (err) {
        console.log(JSON.stringify(err));
        setForgotPass(false);
        setDisplay("none");
        return notifyError();
      },
    });
  } else {
    setForgotPass(false);
    setDisplay("none");
    notifyError({ msg: "Email não encontrado" });
  }
};

export default forgotPasswordProvider;
