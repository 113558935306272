import React, { useState } from "react";
import Cards from "react-credit-cards";

import { TextInput } from "react-admin";
import { makeStyles } from "@material-ui/core";
import { styles, loadThemeMobile } from "../../../services/styles/index";
import ExpiryDateInput from "./ExpiryDateInput";
import CardNumberInput from "./CardNumberInput";
import CardCvcInput from "./CardCvcInput";
import "react-credit-cards/es/styles-compiled.css";

const useStyles = makeStyles(styles);

export const CardInputField = ({ isSmall, required }) => {
  const requiredValidate = required;
  const [focus, setFocus] = useState("");
  const [card_expiry, setCardExpiry] = useState("");
  const [card_number, setCardNumber] = useState("");
  const [card_cvc, setCardCvc] = useState("");
  const [card_name, setCardName] = useState("");

  const classes = useStyles();
  const themeMobile = {};
  loadThemeMobile(isSmall, themeMobile, classes);

  const handleInputFocus = (event) => {
    const { name } = event.target;
    let names = "";
    switch (name) {
      case "card_number":
        names = "number";
        break;
      case "card_name":
        names = "name";
        break;
      case "card_expiry":
        names = "expiry";
        break;
      case "card_cvc":
        names = "cvc";
        break;

      default:
        break;
    }
    setFocus(names);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "card_number":
        setCardNumber(value);
        break;
      case "card_name":
        setCardName(value);
        break;
      case "card_expiry":
        setCardExpiry(value);
        break;
      case "card_cvc":
        setCardCvc(value);
        break;

      default:
        break;
    }
  };

  return (
    <div id="PaymentForm">
      <div style={{ marginTop: 10 }}>
        <TextInput
          label="Número Cartão"
          source="card_number"
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          InputProps={{ inputComponent: CardNumberInput }}
          onChange={(event) => handleInputChange(event)}
          onFocus={(event) => handleInputFocus(event)}
        />
        <TextInput
          label="Nome Titular"
          source="card_name"
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          onChange={(event) => handleInputChange(event)}
          onFocus={(event) => handleInputFocus(event)}
        />
        <TextInput
          label="Mês/Ano"
          source="card_expiry"
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          InputProps={{ inputComponent: ExpiryDateInput }}
          onChange={(event) => handleInputChange(event)}
          onFocus={(event) => handleInputFocus(event)}
        />

        <TextInput
          label="CVC"
          source="card_cvc"
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          InputProps={{ inputComponent: CardCvcInput }}
          onChange={(event) => handleInputChange(event)}
          onFocus={(event) => handleInputFocus(event)}
        />
      </div>
      <Cards
        cvc={card_cvc}
        expiry={card_expiry}
        focused={focus}
        name={card_name}
        number={card_number}
        placeholders={{ name: "NOME TITULAR" }}
        locale={{ valid: "mês/ano" }}
      />
    </div>
  );
};
