import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

function NumberInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={createNumberMask({
        prefix: "",
        thousandsSeparatorSymbol: "",
        requireDecimal: false,
        allowNegative: false,
      })}
      pipe={(value, config) => {
        return value;
      }}
      placeholderChar={"\u2000"}
    />
  );
}

NumberInput.propTypes = { inputRef: PropTypes.func.isRequired };

export default NumberInput;
