import React, { useState } from "react";
import { Box, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { Assignment, Payment } from "@material-ui/icons";
import { statusList, paymentTypeList } from "./common";
import { SaveRecurringPurchaseButton } from "./components/index";
import { CardInputField } from "./components/index";
import { validateCreation } from "./validations/index";
import { styles, loadThemeMobile } from "../../services/styles/index";

import {
  Create,
  Toolbar,
  SimpleForm,
  CheckboxGroupInput,
  SelectInput,
} from "react-admin";

import {
  DadosClient,
  DadosAdderess,
  DadosPlan,
} from "../../services/dados/index";

const useStyles = makeStyles(styles);
const requiredValidate = true;

export const RecurringPurchaseCreate = (props) => {
  const [displayCard, setDisplayCard] = useState(false);
  const classes = useStyles();
  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  loadThemeMobile(isSmall, themeMobile, classes);

  return (
    <Create title="Nova Compra Recorrente" {...props}>
      <SimpleForm validate={validateCreation} toolbar={<CustomToolbar />}>
        <DadosClient />
        <DadosAdderess />
        <SeparatorInit />
        <div style={styles.div_align}>
          <Payment />
          <Typography style={styles.typography_pagamento}>PAGAMENTO</Typography>
        </div>
        <SelectInput
          label="Forma de Pagamento"
          source="payment_type"
          choices={paymentTypeList}
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          onChange={(event) => {
            switch (event.target.value) {
              case "credit":
                setDisplayCard(true);
                break;
              case "boleto":
                setDisplayCard(false);
                break;

              default:
                break;
            }
          }}
          required={requiredValidate}
          defaultValue={"boleto"}
        />
        <SelectInput
          label="Status"
          source="status"
          choices={statusList}
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          value={1}
          disabled
        />
        {displayCard && (
          <CardInputField isSmall={isSmall} required={displayCard} />
        )}
        <SeparatorInit />
        <div style={styles.div_align}>
          <Assignment />
          <Typography style={styles.typography}>ASSINATURA</Typography>
        </div>
        <DadosPlan />
        <SeparatorInit />
        {!displayCard && (
          <CheckboxGroupInput
            source="Parcelamento"
            choices={[{ id: 1, name: "Gerar Boletos Antecipadamente" }]}
          />
        )}
      </SimpleForm>
    </Create>
  );
};

const SeparatorInit = () => <Box pt="1em" />;
const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveRecurringPurchaseButton />
  </Toolbar>
);
