export const validateCreation = (values) => {
  const errors = {};
  const user = JSON.parse(localStorage.getItem("user"));

  if (!values.sale_description) {
    errors.sale_description = ["Descrição da Venda obrigatória"];
  }

  if (values.amount) {
    const newValue = Number(
      values.amount.replace(/[^\d,]+/g, "").replace(",", ".")
    );

    if (!user.limit_payment) {
      user.limit_payment = 1000;
    }

    if (newValue > Number(user.limit_payment)) {
      const limit = Number(user.limit_payment);
      const limitValue = limit.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      errors.amount = [`Valor superior a R$ ${limitValue}`];
    }
  }
  if (!values.amount) {
    errors.amount = ["Valor obrigatório"];
  }
  if (!values.payment_type) {
    errors.payment_type = ["Tipo do Pagamento obrigatório"];
  }
  if (!values.installment_number) {
    errors.installment_number = ["Parcelamento obrigatório"];
  }
  if (values.payment_type) {
    if (values.payment_type.length === 0) {
      errors.payment_type = ["Tipo do Pagamento obrigatório"];
    }
  }

  return errors;
};
