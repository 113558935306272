import Cookies from "universal-cookie";

import { url as apiUrl } from "../config/api";
import permisssionsProvider from "../providers/permisssionsProvider";

const cookies = new Cookies();

const authProvider = {
  login: async ({ email, password, newPassword, confirmNewPassword }) => {
    if (
      newPassword &&
      confirmNewPassword &&
      newPassword !== confirmNewPassword
    ) {
      return Promise.reject("Nova senha diferente do campo de confirmação");
    }

    const request = new Request(`${apiUrl}/auth`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        email,
        password,
        newPassword,
        confirmNewPassword,
      }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    return fetch(request).then(async (response) => {
      const jsonResponse = await response.json();

      if (
        jsonResponse.error &&
        jsonResponse.error.message === "email must be a valid email"
      ) {
        throw new Error("Email inválido");
      }

      if (
        jsonResponse.error &&
        jsonResponse.error.attributes &&
        jsonResponse.error.attributes.status === "EXPIRED_PASSWORD"
      ) {
        return Promise.reject(jsonResponse.error);
      } else if (response.status < 200 || response.status >= 300) {
        throw new Error(jsonResponse.error.message);
      }

      if (jsonResponse.refreshToken && jsonResponse.accessToken) {
        cookies.set("accessToken", jsonResponse.accessToken);
        cookies.set("refreshToken", jsonResponse.refreshToken);
      }

      localStorage.setItem("user", JSON.stringify(jsonResponse.user));
      const { data } = await permisssionsProvider
        .getPermissions(
          jsonResponse.user.profile ? jsonResponse.user.profile : "new"
        )
        .then((result) => result);

      localStorage.setItem("permissions", data.roles);
      return Promise.resolve();
    });
  },

  // called when the user clicks on the logout button
  logout: () => {
    cookies.remove("accessToken");
    cookies.remove("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("user");

    return Promise.resolve();
  },

  // called when the API returns an error
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      cookies.remove("accessToken");
      cookies.remove("refreshToken");
      localStorage.removeItem("permissions");
      localStorage.removeItem("user");

      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return cookies.get("accessToken") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
