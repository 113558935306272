import React from "react";
import { AppBar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Typography } from "@material-ui/core";

import Logo from "../Logo";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const MyAppBar = (props) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <AppBar {...props}>
      {!isSmall && user && user.seller_name && (
        <label style={{ position: "absolute", right: 120, top: 8 }}>
          {user.seller_name}
        </label>
      )}
      {!isSmall && user && user.email && (
        <label
          style={{
            position: "absolute",
            right: 120,
            top: 27,
            fontSize: 14,
          }}
        >
          {user.email}
        </label>
      )}
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="magapay-admin-title"
      />
      <Logo />

      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
