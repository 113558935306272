import React from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";

import {
  AccountBalanceWallet,
} from "@material-ui/icons";

import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
  SelectInput
} from "react-admin";

import { SaveBankAccountButton } from "./components/SaveBankAccountButton";
import { accountTypes } from "./common";
import { validateCreation } from "./validations/index";
import {
  DocumentNumberMaskedInput,
} from "../../services/inputs/index";

import { styles, loadThemeMobile } from "../../services/styles/index";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const useStyles = makeStyles(styles);
const requiredValidate = true;

export const BankAccountCreate = (props) => {
  const classes = useStyles();

  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  loadThemeMobile(isSmall, themeMobile, classes);

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "bank_account");

    if (found !== "bank_account") {
      return <PermissionBlock name={"Conta Bancária"} />;
    }
  }

  return (
    <Create title="Nova Conta" {...props}>
      <SimpleForm validate={validateCreation} toolbar={<CustomToolbar />}>
        <div style={styles.div_align}>
          <AccountBalanceWallet />
          <Typography style={styles.typography}>DADOS DA CONTA</Typography>
        </div>
        <TextInput
          label="Nome do Holder"
          source="holder_name"
          multiline
          resettable
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <TextInput
          label="CPF/CNPJ"
          source="document"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          InputProps={{ inputComponent: DocumentNumberMaskedInput }}
          required={requiredValidate}
        />
        <SeparatorInit />
        <TextInput
          label="Código do Banco"
          source="bank_code"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          parse={(record) => {
            if (record) {
              return record.replace(/\D/g, '');
            }
          }}
          resettable
        />
        <TextInput
          label="Número da agência"
          source="routing_number"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          parse={(record) => {
            if (record) {
              return record.replace(/\D/g, '');
            }
          }}
          resettable
        />
        <TextInput
          label="Número da conta com DV"
          source="account_number"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          parse={(record) => {
            if (record) {
              return record.replace(/\D/g, '');
            }
          }}
          resettable
        />
        <SeparatorInit />
        <SelectInput
          label="Tipo da Conta"
          source="type"
          choices={accountTypes}
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />

      </SimpleForm>
    </Create>
  );
};

const SeparatorInit = () => <Box pt="1em" />;
const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveBankAccountButton />
  </Toolbar>
);
