import React from "react";
import Icon from "@material-ui/core/Icon";
import { Button } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dataProvider from "../../../providers/dataProvider";
const url = process.env.REACT_APP_URL_VENDA_LINK;

const notifyInfo = () => {
  return toast.info("Link encurtado sua área de transferência", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: {
      backgroundColor: "#42b6f5",
    },
    autoClose: 3000,
  });
};

const notifyError = () => {
  return toast.error("Erro ao encurtar sua URL", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: { backgroundColor: "red", opacity: 0.2 },
    autoClose: 3000,
  });
};

toast.configure({
  autoClose: 8000,
  draggable: false,
});

const copyToClipboard = (link) => {
  const el = document.createElement("textarea");
  el.value = link;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const shortCutGenerate = async ({ sale_hash }) => {
  const { data } = await dataProvider
    .create("sale_link/shortcut", {
      data: {
        url: url + sale_hash,
      },
    })
    .then((result) => result)
    .catch(() => false);

  if (data) {
    notifyInfo();
    copyToClipboard(data);
  } else {
    notifyError();
  }
};

export const renderShortcut = (record, isSmall) => {
  let statusLabel = "Encurtar";
  let icons = "call_split";
  let color = "#216e6e";
  let disabled = false;

  return (
    <Button
      label={isSmall ? "" : statusLabel}
      onClick={(event) => {
        event.stopPropagation();
        shortCutGenerate(record);
      }}
      disabled={disabled}
    >
      <Icon style={{ color: color, marginBottom: 2 }}>{icons}</Icon>
    </Button>
  );
};
