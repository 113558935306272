import React, { useCallback } from "react";
import { SaveButton, useCreate, useRedirect } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const SaveNewSalePlanButton = (props) => {
  const [create] = useCreate("sale_plan");
  const redirectTo = useRedirect();
  const { basePath } = props;

  const handleSave = useCallback(
    (values, redirect) => {
      const notifySuccess = () => {
        return toast.success("Plano criado com Sucesso", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          progressStyle: { backgroundColor: "green", opacity: 0.2 },
          autoClose: 3000,
        });
      };

      const notifyError = (error) => {
        return toast.error(
          error ? error.msg : "Valor abaixo de R$5,00 ou acima de R$30.000,00",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            progressStyle: { backgroundColor: "red", opacity: 0.2 },
          }
        );
      };

      toast.configure({
        autoClose: 8000,
        draggable: false,
      });

      const {
        name,
        description,
        frequency,
        interval,
        parcel_value,
        amount,
        installment_number,
      } = values;

      const formatValues = (value) => {
        let newValue = Math.round(
          Number(value.replace(/[^\d,]+/g, "").replace(",", ".")) * 100 +
            Number.EPSILON
        );
        return newValue / 100;
      };

      const getValueFormatted = (value) => {
        return typeof value === "string" ? formatValues(value) : value;
      };

      if (getValueFormatted(parcel_value) < 5) {
        return notifyError({ msg: "Valor por parcela menor do que R$ 5,00" });
      }

      const data = {
        name,
        description,
        frequency,
        interval,
        parcel_value:
          typeof parcel_value === "string"
            ? formatValues(parcel_value)
            : parcel_value,
        amount: typeof amount === "string" ? formatValues(amount) : amount,
        installment_number,
      };

      create(
        {
          payload: { data },
        },
        {
          onSuccess: ({ data: newRecord }) => {
            notifySuccess();
            redirectTo(redirect, basePath, newRecord.id, newRecord);
          },
          onFailure: () => {
            notifyError();
          },
        }
      );
    },
    [create, redirectTo, basePath]
  );

  return <SaveButton {...props} label={"Gerar Plano"} onSave={handleSave} />;
};
