import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";

function EmailInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={emailMask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

EmailInput.propTypes = { inputRef: PropTypes.func.isRequired };

export default EmailInput;
