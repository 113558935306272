import React, { useState } from "react";
import { SaveButton, useCreate, useRedirect } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifySuccess = () => {
  return toast.success("Conta Bancária criada com Sucesso", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: { backgroundColor: "green", opacity: 0.2 },
    autoClose: 3000,
  });
};

const notifyError = (error) => {
  return toast.error(
    error
      ? error.body
      : "Ocorreu um erro ao criar sua conta",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
      progressStyle: { backgroundColor: "red", opacity: 0.2 },
    }
  );
};

toast.configure({
  autoClose: 8000,
  draggable: false,
});

export const SaveBankAccountButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [create] = useCreate("bank_account");
  const redirectTo = useRedirect();
  const { basePath } = props;

  const handleSave = (values, redirect) => {
    setLoading(true);
    const {
      holder_name,
      document,
      bank_code,
      routing_number,
      account_number,
      type,
    } = values;

    const data = {
      holder_name,
      document: document.replace(/\D/g, ''),
      bank_code,
      routing_number,
      account_number,
      type,
    };

    create(
      {
        payload: { data },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          setLoading(false);
          notifySuccess();
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
        onFailure: (error) => {
          setLoading(false);
          notifyError(error);
        },
      }
    );
  };

  return (
    <SaveButton
      {...props}
      label={"gravar"}
      onSave={handleSave}
      saving={loading}
      disabled={loading}
    />
  );
};
