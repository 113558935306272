import { fetchUtils } from "react-admin";
import { url as apiUrl, getDefaultHeaders } from "../config/api";

const httpClient = fetchUtils.fetchJson;

const accountBalanceProvider = {
  getAccountBalance: async () => {
    const { json = [] } = await httpClient(
      `${apiUrl}/analytics/account_balance`,
      {
        headers: getDefaultHeaders(),
        credentials: "include",
      }
    );

    return {
      data: json || [],
    };
  },
};

export default accountBalanceProvider;
