import React, { useCallback } from "react";
import { SaveButton, useCreate, useRedirect } from "react-admin";
import { toast } from "react-toastify";
import { formatISO } from "date-fns";
import "react-toastify/dist/ReactToastify.css";

export const SaveNewTransactionButton = (props) => {
  const [create] = useCreate("transactions");
  const redirectTo = useRedirect();
  const { basePath } = props;

  const handleSave = useCallback(
    (values, redirect) => {
      const notifySuccess = () => {
        return toast.success("Boleto gerado com Sucesso", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          progressStyle: { backgroundColor: "green", opacity: 0.2 },
          autoClose: 3000,
        });
      };

      const notifyError = () => {
        return toast.error("Valor abaixo de R$3,00 ou acima de R$30.000,00", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          progressStyle: { backgroundColor: "red", opacity: 0.2 },
        });
      };

      toast.configure({
        autoClose: 8000,
        draggable: false,
      });

      const {
        description,
        document_number,
        email,
        client_name,
        birthdate,
        phone_number,
        address,
        number,
        complement,
        neighborhood,
        zip_code,
        city,
        state,
        expiration_date,
        split_rules,
      } = values;

      const data = {
        amount: Math.round(
          Number(values.amount.replace(/[^\d,]+/g, "").replace(",", ".")) *
            100 +
            Number.EPSILON
        ),
        description,
        statement_descriptor: values.description,

        customer: {
          document_number: document_number.replace(/[^\d,]+/g, ""),
          email,
          name: client_name,
          birthdate,
          phone_number,
          address: {
            address,
            number,
            complement,
            neighborhood,
            zip_code: zip_code.replace(/\D+/g, ""),
            city,
            state,
          },
        },

        payment_method: {
          expiration_date: formatISO(expiration_date, {
            representation: "date",
          }),
        },
        split_rules: Array.isArray(split_rules)
          ? split_rules.map(({ amount, recipient }) => ({
              recipient,
              amount: Math.round(
                Number(amount.replace(/[^\d,]+/g, "").replace(",", ".")) * 100 +
                  Number.EPSILON
              ),
            }))
          : undefined,
      };

      create(
        {
          payload: { data },
        },
        {
          onSuccess: ({ data: newRecord }) => {
            notifySuccess();
            redirectTo(redirect, basePath, newRecord.id, newRecord);
          },
          onFailure: () => {
            notifyError();
          },
        }
      );
    },
    [create, redirectTo, basePath]
  );

  return <SaveButton {...props} label={"Gerar Boleto"} onSave={handleSave} />;
};
