import React from "react";
import { FunctionField } from "react-admin";

export const renderFrequency = ({ frequency }) => {
  let frequencia = "";

  switch (frequency) {
    case 1:
      frequencia = "Diária";
      break;
    case 2:
      frequencia = "Semanal";
      break;
    case 3:
      frequencia = "Mensal";
      break;
    case 4:
      frequencia = "Anual";
      break;

    default:
      break;
  }
  return <label>{frequencia}</label>;
};

export const FrequencyMethodField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      return renderFrequency(record);
    }}
  />
);
