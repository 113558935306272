import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { AutoSizer, Column, Table } from "react-virtualized";
import { useMediaQuery } from "@material-ui/core";

const styles = (theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      flip: false,
      paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
    },
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const {
      classes,
      columns,
      rowHeight,
      headerHeight,
      ...tableProps
    } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

export default function ReactVirtualizedTable({ record, payment_type, credit_type }) {
  const themes = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    themes.widthTable = "100%";
  } else {
    themes.widthTable = 502;
  }

  if (!record) {
    return null;
  }
  let columns = '';

  switch (payment_type) {
    case 'boleto':
      themes.heightTable = 96;
      columns = [
        {
          width: 150,
          label: "",
          dataKey: "id",
        },
        {
          width: 200,
          label: "",
          dataKey: "id_fake",
        },
        {
          width: 130,
          label: "Tarifa",
          dataKey: "standartBoleto",
        },
      ];
      break;

    case 'credit':
      themes.heightTable = 400;
      let body = [];

      if (credit_type === 'physics' && record.have_physics) {
        record.feesCreditPhysics.forEach(element => {
          let key = Object.keys(element);
          let keySplited = key[0].split('_');
          body.push({
            fee: `${element[key[0]].toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}%`, parcela: keySplited[3] === '1x' ? 'à vista' : keySplited[3], brand: keySplited[2] === 'null' ? 'Padrão' : keySplited[2]
          });
        });
      } else if (credit_type === 'online') {
        record.feesCredit.forEach(element => {
          let key = Object.keys(element);
          let keySplited = key[0].split('_');
          body.push({
            fee: `${element[key[0]].toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}%`, parcela: keySplited[3] === '1x' ? 'à vista' : keySplited[3], brand: keySplited[2] === 'null' ? 'Padrão' : keySplited[2]
          });
        });
      }

      record = body;

      columns = [
        {
          width: 150,
          label: "Parcela",
          dataKey: "parcela",
        },
        {
          width: 200,
          label: "Bandeira",
          dataKey: "brand",
        },
        {
          width: 100,
          label: "Taxa",
          dataKey: "fee",
        },
      ];
      break;

    case 'debit':
      themes.heightTable = 400;
      let bodyDebit = [];

      record.feesDebit.forEach(element => {
        let key = Object.keys(element);
        let keySplited = key[0].split('_');
        bodyDebit.push({
          fee: `${element[key[0]].toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}%`, parcela: keySplited[3] === '1x' ? 'à vista' : keySplited[3], brand: keySplited[2] === 'null' ? 'Padrão' : keySplited[2]
        });
      });

      record = bodyDebit;

      columns = [
        {
          width: 150,
          label: "Parcela",
          dataKey: "parcela",
        },
        {
          width: 200,
          label: "Bandeira",
          dataKey: "brand",
        },
        {
          width: 100,
          label: "Taxa",
          dataKey: "fee",
        },
      ];
      break;

    default:
      break;
  }

  return (
    <>
      <Paper
        style={{
          marginTop: 0,
          height: themes.heightTable,
          width: themes.widthTable,
          alignContent: "center",
        }}
      >
        <VirtualizedTable
          rowCount={record.length}
          rowGetter={({ index }) => {
            if (record[index].standartBoleto) {
              record[index].standartBoleto = `${record[index].standartBoleto.toLocaleString("pt-BR", {
                style: 'currency',
                currency: 'BRL'
              })}`;
            }
            return record[index];
          }}
          columns={columns}
        />
      </Paper>
    </>
  );
}
