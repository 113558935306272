export const validateEdit = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = ["Nome obrigatório"];
  }
  if (!values.description) {
    errors.description = ["Descrição obrigatória"];
  }
  if (!values.frequency) {
    errors.frequency = ["Frequência obrigatória"];
  }
  if (!values.interval) {
    errors.interval = ["Intervalo obrigatório"];
  }
  if (!values.amount) {
    errors.amount = ["Valor Total obrigatório"];
  }
  if (!values.parcel_value) {
    errors.parcel_value = ["Valor por Parcela obrigatório"];
  }
  if (values.parcel_value < 5) {
    errors.parcel_value = ["Valor menor do que R$ 5,00"];
  }
  if (!values.installment_number) {
    errors.installment_number = ["Número de Parcelas obrigatório"];
  }

  return errors;
};
