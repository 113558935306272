import React, { useState } from "react";
import {
  Filter,
  List,
  Datagrid,
  NumberField,
  TextInput,
  TextField,
  useQueryWithStore,
  ReferenceField,
  SelectField,
  FunctionField,
  SelectArrayInput,
  useRefresh,
  usePermissions,
} from "react-admin";
import { DateInput } from "react-admin-date-inputs";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { startOfDay, parseISO, formatISO } from "date-fns";
import {
  useMediaQuery,
  Backdrop,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { NumberInput } from "../../services/inputs/index";
import ListActions from "./components/recurringPurchaseListActions";
import { RecurringPurchaseShow } from "./recurringPurchaseShow";
import { recurringPurchaseExport } from "../exports/index";
import { statusList } from "./common";
import { renderStatus } from "./components/StatusInputField";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const RecurringPurchaseList = (props) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const [ids, setIds] = useState({});
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const RecurringPurchaseFilter = (props) => {
    const setIdLoading = (record) => setIds({ id: record });
    return (
      <Filter {...props}>
        <TextInput
          label="ID"
          source="id"
          parse={(record) => {
            setIdLoading(record);
            return record;
          }}
          InputProps={{ inputComponent: NumberInput }}
          style={{ marginTop: 40 }}
          alwaysOn
        />
        <TextInput
          style={{ marginTop: 40 }}
          label="Nome"
          source="client_name"
        />
        <TextInput
          style={{ marginTop: 40 }}
          label="Nome do Plano"
          source="name"
        />
        <SelectArrayInput
          label="Status"
          source="status"
          choices={statusList}
          style={{ minWidth: 100 }}
        />
        <DateInput
          label="Data Criação"
          source="created_at"
          providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
          options={{ format: "dd/MM/yyyy" }}
          parse={(value) => {
            return value ? formatISO(startOfDay(parseISO(value))) : null;
          }}
          style={{ marginTop: 40 }}
        />
      </Filter>
    );
  };

  const { loading } = useQueryWithStore({
    type: "getList",
    resource: "recurring_purchase",
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: ids,
    },
  });

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find(
      (item) => item === "recurring_purchase"
    );

    if (found !== "recurring_purchase") {
      return <PermissionBlock name={"Compra Recorrente"} />;
    }
  }

  return (
    <List
      {...props}
      sort={{ field: "id", order: "DESC" }}
      filters={<RecurringPurchaseFilter />}
      title="Listar Compras Recorrentes"
      exporter={recurringPurchaseExport}
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      {isSmall ? (
        loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Datagrid rowClick="expand" expand={<RecurringPurchaseShow />}>
            <TextField source="id" label="ID" />
            <ReferenceField
              label={"Nome do Plano"}
              source="plan_id"
              reference="sale_plan"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField
              label="Parcelas"
              source="installment_number"
              sortable={false}
            />
          </Datagrid>
        )
      ) : loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Datagrid rowClick="expand" expand={<RecurringPurchaseShow />}>
          <TextField source="id" label="ID" />
          <TextField label="Nome" source="client_name" sortable={false} />
          <ReferenceField
            label={"Nome do Plano"}
            source="plan_id"
            reference="sale_plan"
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField
            source="amount"
            label="Total"
            options={{ style: "currency", currency: "BRL" }}
            sortable={false}
          />
          <TextField
            label="Parcelas"
            source="installment_number"
            sortable={false}
          />
          <SelectField source="status" choices={statusList} sortable={false} />
          <FunctionField
            render={(record) => {
              return renderStatus(record, refresh);
            }}
          />
        </Datagrid>
      )}
    </List>
  );
};
