import React, { Fragment, useState } from "react";
import {
  ListBase,
  useListContext,
  Filter,
  ListToolbar,
  usePermissions,
} from "react-admin";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Card,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { DateInput } from "react-admin-date-inputs";
import { startOfDay, endOfDay, parseISO, formatISO, addDays } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const Tree = () => {
  const { ids, data } = useListContext();
  const [openChildren, setOpenChildren] = useState([]);

  const toggleNode = (node) =>
    setOpenChildren((state) => {
      if (state.includes(node.id)) {
        return [
          ...state.splice(0, state.indexOf(node.id)),
          ...state.splice(state.indexOf(node.id) + 1, state.length),
        ];
      } else {
        return [...state, node.id];
      }
    });
  const nodes = ids.map((id) => data[id]);
  const roots = nodes.filter((node) => typeof node.parent_id === "undefined");
  const getChildNodes = (root) =>
    nodes.filter((node) => node.parent_id === root.id);
  return (
    <List>
      {roots.map((root) => (
        <SubTree
          key={root.id}
          root={root}
          getChildNodes={getChildNodes}
          openChildren={openChildren}
          toggleNode={toggleNode}
          level={1}
        />
      ))}
    </List>
  );
};

const SubTree = ({ level, root, getChildNodes, openChildren, toggleNode }) => {
  const childNodes = getChildNodes(root);
  const hasChildren = childNodes.length > 0;
  const open = openChildren.includes(root.id);
  return (
    <Fragment>
      <ListItem
        button={hasChildren}
        onClick={() => hasChildren && toggleNode(root)}
        style={{ paddingLeft: level * 16 }}
      >
        {hasChildren && open && <ExpandLess />}
        {hasChildren && !open && <ExpandMore />}
        {!hasChildren && <div style={{ width: 24 }}>&nbsp;</div>}
        <ListItemText primary={root.name} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childNodes.map((node) => (
            <SubTree
              key={node.id}
              root={node}
              getChildNodes={getChildNodes}
              openChildren={openChildren}
              toggleNode={toggleNode}
              level={level + 1}
            />
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};
const FutureTransferFilter = (props) => {
  return (
    <Filter {...props}>
      <DateInput
        label="Data Início"
        source="created_from"
        providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
        options={{ format: "dd/MM/yyyy" }}
        initialvalue={new Date()}
        parse={(value) => (value ? formatISO(parseISO(value)) : null)}
        style={{ marginTop: 40 }}
        alwaysOn
        clearable
      />
      <DateInput
        label="Data Fim"
        source="created_to"
        providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
        options={{ format: "dd/MM/yyyy" }}
        parse={(value) => (value ? formatISO(parseISO(value)) : null)}
        alwaysOn
        clearable
      />
    </Filter>
  );
};

export const FutureTransferList = (props) => {
  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "future/transfer");

    if (found !== "future/transfer") {
      return <PermissionBlock name={"Transferências Futuras"} />;
    }
  }

  return (
    <ListBase
      filterDefaultValues={{
        created_from: formatISO(startOfDay(new Date())),
        created_to: formatISO(endOfDay(addDays(new Date(), 30))),
      }}
      perPage={1000}
      {...props}
    >
      <ListToolbar filters={<FutureTransferFilter />} actions={props.actions} />
      <Box maxWidth="100%" marginTop="1em">
        <Card>
          <Tree />
        </Card>
      </Box>
    </ListBase>
  );
};
