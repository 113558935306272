import React, { useEffect, useState } from "react";
import {
  Typography,
  useMediaQuery,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import { CalendarViewDay, AttachMoney } from "@material-ui/icons";

import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
} from "react-admin";

import { validateCreation } from "./validations/index";
import { SavePaymentBoleto } from "./components/SavePaymentBoleto";
import {
  CurrencyInput,
  NumberInput,
  CurrencyInputBarcode,
} from "../../services/inputs/index";
import { styles, loadThemeMobile } from "../../services/styles/index";
import { dataProvider } from "../../providers/index";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const useStyles = makeStyles(styles);
const requiredValidate = true;

export const PaymentBoletoCreate = (props) => {
  const classes = useStyles();
  const themeMobile = {};
  const [loading, setLoading] = useState(false);
  const [loadingSaldo, setLoadingSaldo] = useState(false);
  const [barCode, setBarCode] = useState(0);
  const [amount, setAmount] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [recipient, setRecipient] = useState("");

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  loadThemeMobile(isSmall, themeMobile, classes);

  if (isSmall) {
    themeMobile.newWidth = "100%";
  } else {
    themeMobile.newWidth = 560;
  }

  const fetchBarCode = async (selectedBarCode) => {
    const { data } = await dataProvider.findBarcode(selectedBarCode);

    if (data.status === 200) {
      const { json } = data;
      setLoading(false);
      setAmount(json.amount);
      setRecipient(json.recipient);
    } else {
      setLoading(false);
      setAmount(0);
      setRecipient("");
    }
  };

  const fetchSaldo = async () => {
    setLoadingSaldo(true);
    const { data } = await dataProvider.getAccountBalance();

    if (data.current_balance) {
      setLoadingSaldo(false);
      setSaldo(
        data.current_balance.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    } else {
      setLoadingSaldo(false);
      setAmount(0);
    }
  };

  useEffect(() => {
    if (!saldo) {
      fetchSaldo();
    }
    if (barCode) {
      fetchBarCode(barCode);
    }
  }, [barCode, saldo]);

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "payment/boleto");

    if (found !== "payment/boleto") {
      return <PermissionBlock name={"Pagar Conta"} />;
    }
  }

  return (
    <Create title="Novo Pagamento" {...props}>
      <SimpleForm validate={validateCreation} toolbar={<CustomToolbar />}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
            width: "100%",
          }}
        >
          <AttachMoney />
          <Typography
            style={{
              paddingLeft: 5,
              paddingTop: 2,
            }}
          >
            SALDO ATUAL
          </Typography>
        </div>
        <TextInput
          label="Saldo"
          source="saldo"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          InputProps={{ inputComponent: CurrencyInput }}
          disabled={true}
          value={saldo}
        />
        {loadingSaldo && <LinearProgress style={styles.linear_progress} />}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
            width: "100%",
          }}
        >
          <CalendarViewDay />
          <Typography
            style={{
              paddingLeft: 5,
              paddingTop: 2,
            }}
          >
            PAGAR CONTA
          </Typography>
        </div>

        <TextInput
          label="Código de Barras"
          source="bar_code"
          style={{ width: themeMobile.newWidth, paddingRight: 50 }}
          required={requiredValidate}
          InputProps={{ inputComponent: NumberInput }}
          onChange={(event) => {
            if (event.target.value) {
              setLoading(true);
              setBarCode(event.target.value);
            }
          }}
        />
        {loading && <LinearProgress style={styles.linear_progress} />}
        <TextInput
          label="Banco Emissor"
          source="recipient"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          placeholder={styles.placeholder_valores}
          disabled={true}
          value={recipient}
        />
        <TextInput
          label="Valor"
          source="amount"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          InputProps={{ inputComponent: CurrencyInputBarcode }}
          placeholder={styles.placeholder_valores}
          disabled={true}
          value={amount}
        />
      </SimpleForm>
    </Create>
  );
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SavePaymentBoleto />
  </Toolbar>
);
