import React from "react";

import { ReferenceField } from "react-admin";

import { useMediaQuery } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useShowController } from "react-admin";
import RecurringPurchaseTransactions from "./recurringPurchaseTransactionsList";

export const RecurringPurchaseShow = (props) => {
  const { record } = useShowController(props);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (!record) return null;
  return (
    <Card>
      <CardContent>
        {!isSmall && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Parcelas: {record.installment_number}
              </Typography>
            </Grid>
          </Grid>
        )}

        {!isSmall && <div>&nbsp;</div>}
        {!isSmall && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom align="center">
                Cliente{" "}
              </Typography>
              <Typography gutterBottom align="center">
                {record.client_name}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography variant="h6" gutterBottom align="center">
                Total
              </Typography>
              <Typography gutterBottom align="center">
                {"R$ "}
                {record.amount &&
                  record.amount.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </Typography>
            </Grid>
          </Grid>
        )}

        <div>
          <ReferenceField
            resource="recurring_purchase/transactions"
            reference="recurring_purchase"
            source="id"
            basePath="/recurring_purchase"
            record={record}
            link={false}
          >
            <RecurringPurchaseTransactions isSmall={isSmall} />
          </ReferenceField>
        </div>
      </CardContent>
    </Card>
  );
};
