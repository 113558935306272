import React from "react";

import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  SelectField,
} from "react-admin";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import GetAppIcon from "@material-ui/icons/GetApp";
import Card from "@material-ui/core/Card";
import { useQueryWithStore, LinearProgress } from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { parseISO, format } from "date-fns";
import { statusList, typeList } from "./common";
import { transferTransactionsExport } from "../exports/index";
import Button from "@material-ui/core/Button";

const TransferTitle = ({ record: { id } }) => <span>Transferência {id}</span>;

export const TransferShow = (props) => {
  const { id } = props;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { loaded, data } = useQueryWithStore({
    type: "getTransferTransactions",
    payload: { transferId: id },
  });

  const tiposPagamento = (value) => {
    const paymentTypeList = {
      credit: `${validaEstorno(value.valor_bruto)}Crédito${getFormaPagamento(
        value
      )}`,
      debit: `${validaEstorno(value.valor_bruto)}Débito`,
      boleto: "Boleto Bancário",
      voucher: "Voucher",
    };

    return paymentTypeList[value.payment_type];
  };

  const getFormaPagamento = (value) => {
    const { parcela, numero_parcelas, payment_type } = value;
    if (parcela > 0) {
      return ` Parcelado ${parcela}/${numero_parcelas}`;
    } else if (parcela === null && payment_type === "credit") {
      return ` à Vista`;
    }

    return "";
  };

  const validaEstorno = (valor_bruto) => {
    if (valor_bruto < 0) {
      return "Estorno ";
    }
    return "";
  };

  const CardMobile = () => {
    if (loaded && data && data.length <= 0) {
      return (
        <p style={{ textAlign: "center" }}>Sem transações até o momento</p>
      );
    } else if (data && data.items && data.items.length > 0) {
      return (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tr>
            <td>
              <strong style={{ paddingLeft: 10, fontSize: "80%" }}>Data</strong>
              <Border />
            </td>

            <td>
              <strong style={{ fontSize: "80%" }}>Referência</strong>
              <Border />
            </td>
            <td>
              <strong style={{ fontSize: "80%" }}>Valor Líquido</strong>
              <Border />
            </td>
          </tr>

          {data.items.map((value, index) => {
            let reference = "";
            let colorEstorno = "";
            let oblique = "";
            const valor_liquido =
              parseFloat(value.valor_bruto) - parseFloat(value.fees);

            if (value.reference_id) {
              reference = value.reference_id;
            } else {
              reference = value.id;
            }

            if (value.valor_bruto < 0) {
              colorEstorno = "rgb(219, 64, 79)";
              oblique = "oblique";
            }

            return (
              <tr
                style={{
                  width: "100%",
                  color: colorEstorno,
                  fontStyle: oblique,
                }}
              >
                <td>
                  <label style={{ paddingLeft: 10, fontSize: "70%" }}>
                    {FormatDateMobile(value.created_at)}
                  </label>
                  <Border />
                </td>

                <td>
                  <label style={{ fontSize: "70%" }}>{reference}</label>
                  <Border />
                </td>
                <td>
                  <label style={{ fontSize: "70%" }}>
                    R$ {FormatMoney(valor_liquido)}
                  </label>
                  <Border />
                </td>
              </tr>
            );
          })}
        </table>
      );
    } else {
      return <LinearProgress style={{ width: "100%" }} />;
    }
  };

  const CardDesktop = () => {
    if (loaded && data && data.length <= 0) {
      return (
        <p style={{ textAlign: "center" }}>
          Sem transações até o momento relacionadas a esta transferência
        </p>
      );
    } else if (data && data.items && data.items.length > 0) {
      return (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tr>
            <td>
              <strong style={{ paddingLeft: 15 }}>Data</strong>
              <Border />
            </td>
            <td>
              <strong>Taxas</strong>
              <Border />
            </td>
            <td>
              <strong>Valor Bruto</strong>
              <Border />
            </td>
            <td>
              <strong>Valor Líquido</strong>
              <Border />
            </td>
            <td>
              <strong>Pagamento</strong>
              <Border />
            </td>
            <td>
              <strong>Referência</strong>
              <Border />
            </td>
          </tr>

          {data.items.map((value, index) => {
            let reference = "";
            let colorEstorno = "";
            let oblique = "";
            const valor_liquido =
              parseFloat(value.valor_bruto) - parseFloat(value.fees);
            const valor_bruto = parseFloat(value.valor_bruto);
            const taxas = parseFloat(value.fees);
            const tipo_pagamento = tiposPagamento(value);

            if (value.reference_id) {
              reference = value.reference_id;
            } else {
              reference = value.id;
            }

            if (valor_bruto < 0) {
              colorEstorno = "rgb(219, 64, 79)";
              oblique = "oblique";
            }

            return (
              <tr
                style={{
                  width: "100%",
                  color: colorEstorno,
                  fontStyle: oblique,
                }}
              >
                <td>
                  <label style={{ paddingLeft: 15 }}>
                    {FormatDateDesktop(value.created_at)}
                  </label>
                  <Border />
                </td>
                <td>
                  <label>R$ {FormatMoney(taxas)}</label>
                  <Border />
                </td>
                <td>
                  <label>R$ {FormatMoney(valor_bruto)}</label>
                  <Border />
                </td>
                <td>
                  <label>R$ {FormatMoney(valor_liquido)}</label>
                  <Border />
                </td>
                <td>
                  <label>{tipo_pagamento}</label>
                  <Border />
                </td>
                <td>
                  <label>{reference}</label>
                  <Border />
                </td>
              </tr>
            );
          })}
        </table>
      );
    } else {
      return <LinearProgress style={{ width: "100%" }} />;
    }
  };

  return (
    <div>
      <Show title={<TransferTitle />} {...props}>
        <SimpleShowLayout>
          <DateField
            source="created_at"
            label="Data da Transferência"
            showTime={true}
          />
          <SelectField
            source="type"
            label="Tipo de Pagamento"
            choices={typeList}
          />
          <SelectField source="status" choices={statusList} />
          <NumberField
            source="amount"
            label="Valor"
            options={{ style: "currency", currency: "BRL" }}
          />
          <TextField source="transfer_number" label="Número da Transferência" />
          <TextField source="bank_account.holder_name" label="Favorecido" />
          <TextField source="bank_account.bank_name" label="Banco" />
          <TextField source="bank_account.routing_number" label="Agência" />
          <TextField source="bank_account.account_number" label="Conta" />
        </SimpleShowLayout>
      </Show>
      <ButtonExporter {...[data, loaded, isSmall]} />
      <CardTitulo />
      <Card style={{ marginTop: 10 }}>
        {isSmall ? <CardMobile /> : <CardDesktop />}
      </Card>
    </div>
  );
};

const CardTitulo = () => {
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Card
          style={{
            background: "rgb(255, 152, 0)",
            color: "#fff",
            marginLeft: 10,
            marginTop: 10,
            display: "inline-block",
          }}
        >
          <AttachMoneyIcon style={{ height: 50, width: 50 }} />
        </Card>
        <strong
          style={{
            display: "inline-block",
            paddingLeft: 20,
            marginTop: 10,
            fontSize: "170%",
            filter: "brightness(0)",
          }}
        >
          TRANSAÇÕES
        </strong>
      </div>
    </div>
  );
};

const ButtonExporter = (data) => {
  const transactions = data[0];
  const loaded = data[1];
  const isSmall = data[2];
  let disabled = "";
  let marginRightButton = 0;

  if (transactions && transactions.length <= 0 && loaded) {
    disabled = "true";
  }

  if (isSmall) {
    marginRightButton = 10;
  }

  if (!loaded) {
    disabled = "true";
  }
  if (
    loaded &&
    transactions &&
    transactions.items &&
    transactions.items.length === 0
  ) {
    disabled = "true";
  }

  return (
    <Button
      style={{ float: "right", marginTop: 20, marginRight: marginRightButton }}
      variant="contained"
      type="button"
      color="primary"
      onClick={() => transferTransactionsExport(transactions)}
      disabled={disabled}
    >
      {isSmall ? (
        <GetAppIcon />
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <GetAppIcon />
          <label style={{ marginLeft: 4, cursor: "pointer" }}>Exportar</label>
        </div>
      )}
    </Button>
  );
};

const FormatMoney = (value) => {
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const FormatDateDesktop = (date) => {
  return format(parseISO(date), "dd/MM/yyyy' - 'HH:mm:ss");
};

const FormatDateMobile = (date) => {
  return format(parseISO(date), "dd/MM/yyyy");
};

const Border = () => (
  <div
    style={{
      borderBottom: `1px solid
      rgba(224, 224, 224, 1)`,
      width: "100%",
    }}
  ></div>
);
