import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { useMediaQuery } from "@material-ui/core";
import { AccountBalance } from "./accountBalance";

import {
  startOfDay,
  endOfDay,
  formatISO,
  parseISO,
  startOfMonth,
} from "date-fns";
import { List, usePermissions } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import { Summary } from "./summary";
import { DateInput } from "react-admin-date-inputs";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { Filter, SimpleList } from "react-admin";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const useStyles = makeStyles({
  root: {
    background: "#fff",
  },
});

const style = {
  div: { display: "flex", textAlign: "center" },
  filter: { marginLeft: -8, display: "none" },
};

export const Dashboard = () => {
  const [propsFilter, setProps] = useState({});

  let props = {
    basePath: "/",
    hasCreate: false,
    hasEdit: false,
    hasList: false,
    hasShow: false,
    history: {},
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: false, params: {} },
    options: {},
    permissions: null,
    resource: "resumo",
  };

  const SummaryFilter = (props) => {
    const themeMobile = {};
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    if (isSmall) {
      themeMobile.widthSmaller = "100%";
      themeMobile.marginLeft = "4%";
      themeMobile.widthSmallerTwo = "100%";
      themeMobile.marginLeftTwo = "4%";
    } else {
      themeMobile.widthSmaller = 226;
      themeMobile.marginLeft = "3.5%";
      themeMobile.widthSmallerTwo = 220;
      themeMobile.marginLeftTwo = "0.4%";
    }

    return (
      <Filter {...props}>
        <DateInput
          label="Data Início"
          source="date_from"
          providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
          options={{ format: "dd/MM/yyyy" }}
          initialvalue={new Date()}
          parse={(value) => (value ? formatISO(parseISO(value)) : null)}
          style={{
            width: themeMobile.widthSmaller,
            marginLeft: themeMobile.marginLeft,
            marginTop: 40,
          }}
          alwaysOn
          onChange={(value) =>
            setProps({
              ...propsFilter,
              ...{ created_from: formatISO(startOfDay(parseISO(value))) },
            })
          }
        />

        <DateInput
          label="Data Fim"
          source="date_to"
          providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
          options={{ format: "dd/MM/yyyy" }}
          parse={(value) => (value ? formatISO(parseISO(value)) : null)}
          style={{
            width: themeMobile.widthSmallerTwo,
            marginLeft: themeMobile.marginLeftTwo,
          }}
          alwaysOn
          onChange={(value) =>
            setProps({
              ...propsFilter,
              ...{ created_to: formatISO(endOfDay(parseISO(value))) },
            })
          }
        />
      </Filter>
    );
  };

  const classes = useStyles();
  const newClass = {};
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const PostPagination = () => {
    const nbPages = 1;
    return nbPages > 1 && <Toolbar></Toolbar>;
  };

  useEffect(() => {
    let propsFilter = {};
    propsFilter.created_from = document.getElementById("date_from");
    propsFilter.created_to = document.getElementById("date_to");
    if (propsFilter.created_to && propsFilter.created_from) {
      if (propsFilter.created_to.value && propsFilter.created_from.value) {
        let init = propsFilter.created_from.value.split("/");
        let final = propsFilter.created_to.value.split("/");
        let dateInit = `${init[2]}-${init[1]}-${init[0]}`;
        let dateFinal = `${final[2]}-${final[1]}-${final[0]}`;
        propsFilter.created_from = formatISO(startOfDay(parseISO(dateInit)));
        propsFilter.created_to = formatISO(endOfDay(parseISO(dateFinal)));
      }
    }
    setProps(propsFilter);
  }, []);

  if (isSmall) {
    style.div = {};
    newClass.width = "90%";
    newClass.marginLeft = "5%";
    newClass.marginLeftSaldo = "5%";
    newClass.marginTop = 10;
    newClass.marginTopSummary = 10;
    newClass.display_inlineBlock = "";
    newClass.marginRight = 0;
  } else {
    newClass.marginRight = 20;
    newClass.width = "";
    newClass.marginLeftSaldo = 0;
    newClass.marginTop = 20;
    newClass.marginTopSummary = 20;
    newClass.display_inlineBlock = "inline-block";
    newClass.float = "left";
  }

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "dashboard");

    if (found !== "dashboard") {
      return <PermissionBlock name={"Painel de Controle"} />;
    }
  }

  return (
    <div>
      <div id="div_filter" style={style.filter}>
        <List
          {...props}
          perPage={1}
          pagination={<PostPagination />}
          filters={<SummaryFilter />}
          filterDefaultValues={{
            date_from: formatISO(startOfMonth(new Date())),
            date_to: formatISO(endOfDay(new Date())),
          }}
          exporter={false}
          bulkActionButtons={false}
        >
          <SimpleList></SimpleList>
        </List>
      </div>
      <Card
        className={classes.root}
        style={{
          display: newClass.display_inlineBlock,
          width: newClass.width,
          marginLeft: newClass.marginLeft,
          marginRight: newClass.marginRight,
          marginBottom: 0,
          marginTop: newClass.marginTopSummary,
          float: newClass.float,
        }}
      >
        {propsFilter && <Summary {...propsFilter} />}
      </Card>

      <Card
        className={classes.root}
        style={{
          display: newClass.display_inlineBlock,
          width: newClass.width,
          marginLeft: newClass.marginLeftSaldo,
          marginTop: newClass.marginTop,
          marginRight: 20,
          marginBottom: 0,
          float: newClass.float,
        }}
      >
        <AccountBalance />
      </Card>
    </div>
  );
};
