import React from "react";
import { FunctionField } from "react-admin";
import Icon from "@material-ui/core/Icon";
import { Button } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const url = process.env.REACT_APP_URL_VENDA_LINK;

const notifyInfo = () => {
  return toast.info("Link em sua área de transferência", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: {
      backgroundColor: "#42b6f5",
    },
    autoClose: 3000,
  });
};

toast.configure({
  autoClose: 8000,
  draggable: false,
});

export const renderCopy = (record, isSmall) => {
  let statusLabel = "Copiar";
  let icons = "dynamic_feed";
  let color = "#4284f5";
  let disabled = false;

  const copyToClipboard = ({ sale_hash }) => {
    const el = document.createElement("textarea");
    el.value = url + sale_hash;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <Button
      label={isSmall ? "" : statusLabel}
      onClick={(event) => {
        event.stopPropagation();
        notifyInfo();
        return copyToClipboard(record);
      }}
      disabled={disabled}
    >
      <Icon style={{ color: color, marginBottom: 2 }}>{icons}</Icon>
    </Button>
  );
};

export const CopyMethodField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      return renderCopy(record);
    }}
  />
);
