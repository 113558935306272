import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (posts) => {
  const postsForExport = posts.map((post) => {
    post.ID = post.id;
    post.Seller_ID = post.seller_id;
    post.Nome = post.name;
    post.Descricao = post.description;
    post.Frequencia = post.frequency;
    post.Intervalo = post.interval;
    post.Valor_Total = `R$ ${post.amount.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
    post.Valor_Parcela = `R$ ${post.parcel_value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
    post.Parcelas = post.installment_number;

    return post;
  });

  const csv = convertToCSV({
    data: postsForExport,
    fields: [
      "ID",
      "Seller_ID",
      "Nome",
      "Descricao",
      "Frequencia",
      "Intervalo",
      "Valor_Total",
      "Valor_Parcela",
      "Parcelas",
    ],
  });
  downloadCSV(csv, "plano_assinatura");
};

export default exporter;
