import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  FunctionField,
} from "react-admin";

import VirtualizedTable from "../saleByLink/virtualizedTable";

const url = process.env.REACT_APP_URL_VENDA_LINK;

const DonateTitle = () => <span>Detalhes da Venda por Doação</span>;

const FormatValue = (value) => {
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const DonateShow = (props) => {
  return (
    <Show title={<DonateTitle />} {...props}>
      <SimpleShowLayout>
        <NumberField
          source="amount"
          label="Valor mínimo da doação"
          options={{ style: "currency", currency: "BRL" }}
        />
        <TextField source="sale_description" label="Descrição da Venda" />
        <FunctionField
          label="Regras de Valor"
          render={({ value_rules }) => {
            if (value_rules) {
              return `R$ ${FormatValue(value_rules[0])} / R$ ${FormatValue(
                value_rules[1]
              )} / R$ ${FormatValue(value_rules[2])} / R$ ${FormatValue(
                value_rules[3]
              )} `;
            }
          }}
        />
        <FunctionField
          label="Link de Pagamento"
          render={(record) => `${url}${record.sale_hash}`}
        />
        <VirtualizedTable isSaleLink={false} />
      </SimpleShowLayout>
    </Show>
  );
};
