import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

function getFormatCpfCnpj(newValue) {
  if (newValue.length <= 11) {
    return [
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
    ];
  } else if (newValue.length > 11) {
    return [
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
    ];
  }
}

function DocumentNumberMaskedInput(props) {
  const { value, inputRef, ...other } = props;
  const newValue = value.replace(/\D+/g, "");

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={getFormatCpfCnpj(newValue)}
      guide={true}
      placeholderChar={" "}
    />
  );
}

DocumentNumberMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default DocumentNumberMaskedInput;
