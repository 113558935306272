import { downloadCSV } from "react-admin";

import { format } from "date-fns";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const types = {
  charge: "Venda",
  charge_refunded: "Venda Estornada",
  transfer: "Transferencia",
  transfer_refunded: "Transferencia Estornada",
  deposit: "Deposito",
  deposit_refunded: "Deposito Estornado",
  adjustment: "Ajuste em Conta",
  pix: "PIX",
};

const exporter = (posts) => {
  const postsForExport = posts.map((post) => {
    post.Tipo = types[post.type];
    post.ID = post.id;
    post.Data = format(new Date(post.date), "dd/MM/yyyy");
    post.NSU_Operacao = post.nsu;
    post.Taxa = `R$ ${post.new_fee.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
    post.Valor = `R$ ${post.new_gross_amount.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
    post.Valor_liquido = `R$ ${post.new_amount.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;

    return post;
  });

  const csv = convertToCSV({
    data: postsForExport,
    fields: [
      "Tipo",
      "ID",
      "Data",
      "NSU_Operacao",
      "Taxa",
      "Valor",
      "Valor_liquido",
    ],
  });
  downloadCSV(csv, "Extrato");
};

export default exporter;
