export const validateCreation = (values) => {
  const errors = {};

  if (!values.sale_description) {
    errors.sale_description = ["Descrição da Doação obrigatória"];
  }

  if (!values.payment_type) {
    errors.payment_type = ["Tipo do Pagamento obrigatório"];
  }

  if (values.payment_type) {
    if (values.payment_type.length === 0) {
      errors.payment_type = ["Tipo do Pagamento obrigatório"];
    }
  }

  if (values.value_rule_01) {
    const newValue = Number(
      values.value_rule_01.replace(/[^\d,]+/g, "").replace(",", ".")
    );

    if (newValue < 20) {
      errors.value_rule_01 = ["Valor mínimo R$ 20,00 para doação"];
    }
    if (newValue > 1000) {
      errors.value_rule_01 = ["Valor máximo R$ 1.000,00 para doação"];
    }
  } else {
    errors.value_rule_01 = ["Primeiro Valor obrigatório"];
  }

  if (values.value_rule_02) {
    const newValue = Number(
      values.value_rule_02.replace(/[^\d,]+/g, "").replace(",", ".")
    );

    if (newValue < 20) {
      errors.value_rule_02 = ["Valor mínimo R$ 20,00 para doação"];
    }
    if (newValue > 1000) {
      errors.value_rule_02 = ["Valor máximo R$ 1.000,00 para doação"];
    }
  } else {
    errors.value_rule_02 = ["Segundo Valor obrigatório"];
  }

  if (values.value_rule_03) {
    const newValue = Number(
      values.value_rule_03.replace(/[^\d,]+/g, "").replace(",", ".")
    );

    if (newValue < 20) {
      errors.value_rule_03 = ["Valor mínimo R$ 20,00 para doação"];
    }
    if (newValue > 1000) {
      errors.value_rule_03 = ["Valor máximo R$ 1.000,00 para doação"];
    }
  } else {
    errors.value_rule_03 = ["Terceiro Valor obrigatório"];
  }

  if (values.value_rule_04) {
    const newValue = Number(
      values.value_rule_04.replace(/[^\d,]+/g, "").replace(",", ".")
    );

    if (newValue < 20) {
      errors.value_rule_04 = ["Valor mínimo R$ 20,00 para doação"];
    }
    if (newValue > 1000) {
      errors.value_rule_04 = ["Valor máximo R$ 1.000,00 para doação"];
    }
  } else {
    errors.value_rule_04 = ["Quarto Valor obrigatório"];
  }

  return errors;
};
