import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

function CepMaskedInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
      guide={false}
      showMask
    />
  );
}

CepMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default CepMaskedInput;
