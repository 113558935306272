import { downloadCSV } from "react-admin";

import { format } from "date-fns";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const status = {
  1: "Ativo",
  2: "Finalizado",
  3: "Cancelado",
};

const paymentType = {
  credit: "Credito",
  debit: "Debito",
  boleto: "Boleto",
};

const exporter = (posts) => {
  const postsForExport = posts.map((post) => {
    post.ID = post.id;
    post.Seller_ID = post.seller_id;
    post.Plano_ID = post.plan_id;
    post.Cliente_ID = post.client_id;
    post.Cliente_Nome = post.client_name;
    post.Descricao = post.description;
    post.Status = status[post.status];
    post.Pagamento = paymentType[post.payment_type];
    post.Valor_Total = `R$ ${post.amount.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
    post.Valor_Parcela = `R$ ${post.parcel_value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
    post.Parcelas = post.installment_number;
    post.Data_Primeira_Cobranca = format(
      new Date(post.first_billing_date),
      "dd/MM/yyyy"
    );

    return post;
  });

  const csv = convertToCSV({
    data: postsForExport,
    fields: [
      "ID",
      "Seller_ID",
      "Plano_ID",
      "Cliente_ID",
      "Cliente_Nome",
      "Descricao",
      "Status",
      "Pagamento",
      "Valor_Total",
      "Valor_Parcela",
      "Parcelas",
      "Data_Primeira_Cobranca",
    ],
  });
  downloadCSV(csv, "compra_recorrente");
};

export default exporter;
