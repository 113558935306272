import React, { useState } from "react";
import {
  Filter,
  List,
  Datagrid,
  NumberField,
  TextInput,
  TextField,
  useQueryWithStore,
  EditButton,
  DeleteButton,
  FunctionField,
  usePermissions,
} from "react-admin";

import { NumberInput } from "../../services/inputs/index";
import {
  makeStyles,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import ListActions from "./components/salePlanListActions";
import { salePlanExport } from "../exports/index";
import { renderInterval } from "./components/IntervaloInputField";
import { renderFrequency } from "./components/FrequencyInputField";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const SalePlanList = (props) => {
  const classes = useStyles();
  const [ids, setIds] = useState({});
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const SalePlanFilter = (props) => {
    const setIdLoading = (record) => setIds({ id: record });
    return (
      <Filter {...props}>
        <TextInput
          label="ID"
          source="id"
          parse={(record) => {
            setIdLoading(record);
            return record;
          }}
          InputProps={{ inputComponent: NumberInput }}
          style={{ marginTop: 40 }}
          alwaysOn
        />
        <TextInput
          label="Nome do Plano"
          source="name"
          style={{ marginTop: 40 }}
          alwaysOn
        />
      </Filter>
    );
  };

  const { loading } = useQueryWithStore({
    type: "getList",
    resource: "sale_plan",
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: ids,
    },
  });

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "sale_plan");

    if (found !== "sale_plan") {
      return <PermissionBlock name={"Planos de Assinatura"} />;
    }
  }

  return (
    <List
      {...props}
      sort={{ field: "id", order: "DESC" }}
      filters={<SalePlanFilter />}
      title="Listar Planos"
      exporter={salePlanExport}
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      {isSmall ? (
        loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Datagrid rowClick="show">
            <TextField source="id" label="ID" />
            <TextField label="Nome" source="name" />
            <EditButton />
            <DeleteButton />
          </Datagrid>
        )
      ) : loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Datagrid rowClick="show">
          <TextField source="id" label="ID" />
          <TextField label="Nome do Plano" source="name" />
          <FunctionField
            label="Frequência"
            render={(record) => renderFrequency(record)}
          />
          <FunctionField
            label="Intervalo"
            render={(record) => renderInterval(record)}
          />
          <NumberField
            source="amount"
            label="Valor Total do Plano"
            options={{ style: "currency", currency: "BRL" }}
            sortable={false}
          />
          <NumberField
            source="parcel_value"
            label="Valor por Parcela"
            options={{ style: "currency", currency: "BRL" }}
            sortable={false}
          />
          <NumberField
            source="installment_number"
            label="Número de Parcelas"
            sortable={false}
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};
