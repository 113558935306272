import React, { useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Card, createMuiTheme, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import { useHistory } from "react-router-dom";
import { useCheckAuth } from "ra-core";
import { useMediaQuery } from "@material-ui/core";

import defaultTheme from "./defaultTheme";
import Notification from "./Notification";
import DefaultLoginForm from "./LoginForm";
import logo from "../../logo.png";

const useStyles = makeStyles(
  theme => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: "#822150"
    },
    card: {
      minWidth: 460,
      marginTop: "6em"
    },
    avatar: {
      margin: "1em",
      display: "flex",
      justifyContent: "center"
    },
    logo: {
      width: 250
    },
    separator: {
      width: "90%",
      opacity: "50%"
    }
  }),
  { name: "RaLogin" }
);

const styles = {
  card: { minWidth: "flex", marginTop: "6em" }
};

const useStyleCard = makeStyles(styles);

const Login = props => {
  const {
    theme,
    classes: classesOverride,
    className,
    children,
    staticContext,
    backgroundImage,
    ...rest
  } = props;

  const notSmall = useMediaQuery("(min-width:450px)");
  const containerRef = useRef();
  const classes = useStyles(props);
  const classCard = useStyleCard(props);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
  const checkAuth = useCheckAuth();
  const themeCard = {};

  if (notSmall) {
    themeCard.card = classes.card;
  } else {
    themeCard.card = classCard.card;
  }

  const history = useHistory();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push("/");
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  return (
    <ThemeProvider theme={muiTheme}>
      <div
        className={classnames(classes.main, className)}
        {...rest}
        ref={containerRef}
      >
        <Card className={themeCard.card}>
          <div className={classes.avatar}>
            <img alt="logo" src={logo} className={classes.logo} />
          </div>
          <hr className={classes.separator}></hr>
          {children}
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object
};

Login.defaultProps = {
  theme: defaultTheme,
  children: <DefaultLoginForm />
};

export default Login;
