import React from "react";
import { FunctionField } from "react-admin";
import Button from "@material-ui/core/Button";

export const renderBilletGenerationMethod = ({ payment_method }) => {
  if (payment_method.payment_type === "boleto") {
    return (
      <div>
        <label class="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-197 MuiInputLabel-animated MuiInputLabel-shrink ">
          Impressão do Boleto:
        </label>
        <Button
          variant="contained"
          color="primary"
          target="_blank"
          href={payment_method.url}
          style={{ marginTop: 10 }}
        >
          IMPRIMIR
        </Button>
        <label
          style={{ marginTop: 20 }}
          class="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-197 MuiInputLabel-animated MuiInputLabel-shrink "
        >
          Código do Boleto:
        </label>
        <div style={{ marginTop: 6 }}>{payment_method.barcode}</div>
      </div>
    );
  }
};

export const BilletGenerationField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      return renderBilletGenerationMethod(record);
    }}
  />
);
