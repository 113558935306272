import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configuration from "../config/cognito";

const notifySuccess = () => {
  return toast.success("Senha redefinida com Sucesso", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: { backgroundColor: "green", opacity: 0.2 },
    autoClose: 3000,
  });
};
const notifyError = (error) => {
  return toast.error(
    error
      ? error.msg
      : "A senha deve conter, Caracter especial, Letra Maiuscula, Número e no Mínimo 8 caracteres Ex: #Senha01",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
      progressStyle: { backgroundColor: "#f56262", opacity: 0.2 },
    }
  );
};

const getCognitoUserPool = async () => {
  return new CognitoUserPool(configuration);
};

const getCognitoUser = async (email) => {
  return new CognitoUser({
    Username: email,
    Pool: await getCognitoUserPool(),
  });
};

const confirmPasswordProdiver = async (
  email,
  code,
  newPassword,
  setForgotPass,
  setDisplay
) => {
  const cognitoUser = await getCognitoUser(email)
    .then((result) => {
      if (result) {
        return Promise.resolve(result);
      }
    })
    .catch((error) => {
      if (error) {
        return Promise.resolve(error);
      }
    });

  cognitoUser.confirmPassword(code, newPassword, {
    onSuccess() {
      console.log("Password confirmed!");
      setForgotPass(false);
      setDisplay("none");
      return notifySuccess();
    },
    onFailure(err) {
      console.log("Password not confirmed!");
      setForgotPass(false);
      setDisplay("none");
      return notifyError();
    },
  });
};

export default confirmPasswordProdiver;
