import { fetchUtils } from "react-admin";
import { url as apiUrl, getDefaultHeaders } from "../config/api";

const httpClient = fetchUtils.fetchJson;

const permisssionsProvider = {
  getPermissions: async (name) => {
    const { json = [] } = await httpClient(`${apiUrl}/permissions/${name}`, {
      headers: getDefaultHeaders(),
      credentials: "include",
    });

    return {
      data: json || [],
    };
  },
};

export default permisssionsProvider;
