import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

import { url as apiUrl, getDefaultHeaders } from "../config/api";

const httpClient = fetchUtils.fetchJson;

const documentNumberProvider = {
  getCpfCnpj: async cpfCnpj => {
    const query = {
      document_number: cpfCnpj
    };

    const { json = [] } = await httpClient(
      `${apiUrl}/customers/search?${stringify(query)}`,
      {
        headers: getDefaultHeaders(),
        credentials: "include"
      }
    );

    return {
      data: json || []
    };
  }
};

export default documentNumberProvider;
