import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import { Print } from "@material-ui/icons";
import { useQueryWithStore, LinearProgress, useRedirect } from "react-admin";
import { AnimationWrapper } from "react-hover-animation";

const statusList = [
  { id: 1, name: "Pendente", color: "#5d5abf" },
  { id: 2, name: "Aguardando Pagamento", color: "#FFCC00" },
  { id: 3, name: "Paga", color: "#419c41" },
  { id: 4, name: "Cancelado Pagamento", color: "#FF887C" },
  { id: 5, name: "Cancelada", color: "#ed0952" },
  { id: 6, name: "Estornada", color: "#575c5b" },
];

const RecurringPurchaseTransactions = ({ record, isSmall }) => {
  const redirect = useRedirect();
  const RedirectTransactionShow = (id) => {
    if (id) {
      redirect(`/transactions/${id}/show`);
    }
  };

  const { loaded, data: products } = useQueryWithStore({
    type: "getList",
    resource: "recurring_purchase/transactions",
    payload: {
      pagination: { page: 1, perPage: record.installment_number },
      sort: { field: "recurring_purchase", order: "DESC" },
      filter: { recurring_purchase: record.id },
    },
  });
  const themeLoadingStatus = {};

  if (isSmall) {
    themeLoadingStatus.paddingTop = 70;
    themeLoadingStatus.paddingLeft = 0;
    themeLoadingStatus.paddingRight = 5;
    themeLoadingStatus.paddingBottom = 4;
  } else {
    themeLoadingStatus.paddingTop = 60;
    themeLoadingStatus.paddingLeft = 0;
    themeLoadingStatus.paddingRight = 5;
    themeLoadingStatus.paddingBottom = 2;
  }

  if (!loaded || !record) {
    return <LinearProgress />;
  } else if (loaded && products.length === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <label>Nenhum registro encontrado</label>
      </div>
    );
  }

  return (
    <Paper elevation={5}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <label>Parcela</label>
            </TableCell>
            <TableCell>
              <label>Valor</label>
            </TableCell>
            {!isSmall && (
              <TableCell>
                <label>Status</label>
              </TableCell>
            )}
            <TableCell>
              <label>Data</label>
            </TableCell>
            <TableCell>
              <label></label>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((item) => {
            const dueDateParcel = item.date.split("-");
            const dayParcel = dueDateParcel[2].split("T");
            const newDueDateParcel = `${dayParcel[0]}/${dueDateParcel[1]}/${dueDateParcel[0]}`;
            const cursor = { pointer: "" };
            const boleto = { disabled: true };
            const active = { show: true };

            if (item.transaction_found) {
              if (item.transaction_found.payment_type === "boleto") {
                if (item.transaction_found.zoop_transaction) {
                  boleto.url =
                    item.transaction_found.zoop_transaction.payment_method.url;
                } else {
                  boleto.url = item.transaction_found.payment_method.url;
                }

                if (item.status !== 5) {
                  boleto.disabled = false;
                }
              }
              cursor.pointer = "pointer";
              active.show = false;
            }

            return (
              <TableRow
                id={"linha_parcela"}
                key={item.id}
                style={{
                  cursor: cursor.pointer,
                }}
                onClick={() => RedirectTransactionShow(item.trans_id)}
              >
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        position: "absolute",
                        paddingTop: themeLoadingStatus.paddingTop,
                        paddingLeft: themeLoadingStatus.paddingLeft,
                        paddingRight: themeLoadingStatus.paddingRight,
                        paddingBottom: themeLoadingStatus.paddingBottom,
                        background: statusList[item.status - 1].color,
                      }}
                    ></div>
                    <div style={{ marginLeft: 20 }}>{item.parcel}</div>
                  </div>
                </TableCell>
                <TableCell>
                  {"R$ "}
                  {record.parcel_value.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
                {!isSmall && (
                  <TableCell>{statusList[item.status - 1].name}</TableCell>
                )}
                <TableCell>{newDueDateParcel}</TableCell>
                <TableCell>
                  <AnimationWrapper reset={boleto.disabled}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        padding: 2,
                        margin: 0,
                        height: 28,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        var win = window.open(boleto.url, "_blank");
                        return win.focus();
                      }}
                      disabled={boleto.disabled}
                    >
                      <Print />
                    </Button>
                  </AnimationWrapper>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default RecurringPurchaseTransactions;
