import React from "react";
import { useForm } from "react-final-form";
import { parseISO, formatISO } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { DateInput } from "react-admin-date-inputs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { FormatDatesParcel } from "../index";

const DadosParcel = ({ products }) => {
  const form = useForm();
  const produtos = [];
  const installmentNumber = products.id;

  for (let parcel = 1; parcel <= installmentNumber; parcel++) {
    const newDateFormatted = FormatDatesParcel(
      parseISO(products.date, "yyyy-MM-ddThh:mm", new Date()),
      parcel,
      Number(products.frequency),
      Number(products.interval)
    );
    produtos.push({ id: parcel, parcel, date: newDateFormatted });
    form.change(`date_${parcel}`, formatISO(newDateFormatted));
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <Paper elevation={5}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <label>Parcela</label>
              </TableCell>
              <TableCell>
                <label>Valor</label>
              </TableCell>
              <TableCell>
                <label>Data</label>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {produtos.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.parcel}</TableCell>
                  {products.isNewLoading ? (
                    <TableCell>
                      {"R$ "}
                      {products.parcel_value.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                  ) : (
                    <TableCell>{products.parcel_value}</TableCell>
                  )}
                  <TableCell>
                    <DateInput
                      label="Cobrança"
                      source={`date_${item.id}`}
                      variant="outlined"
                      providerOptions={{
                        utils: DateFnsUtils,
                        locale: ptBrLocale,
                      }}
                      options={{
                        format: "dd/MM/yyyy",
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default DadosParcel;
