import React from "react";
import { useMediaQuery } from "@material-ui/core";

import {
  useQueryWithStore,
  LinearProgress,
  Error,
  AutocompleteInput,
} from "react-admin";

export const SellerSplitSelectInput = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const themeMobile = {};
  if (isSmall) {
    themeMobile.width = "100%";
  } else {
    themeMobile.width = 256;
  }

  const { loaded, error, data } = useQueryWithStore({
    type: "getList",
    resource: "sellers_split",
    payload: { pagination: { page: 1, perPage: 50 }, filter: {}, sort: {} },
  });

  if (error) {
    return <Error />;
  }

  const optionRenderer = ({ name, document_number }) =>
    `${name} (${document_number})`;

  return (
    <>
      <AutocompleteInput
        {...props}
        choices={data}
        optionText={optionRenderer}
        options={{
          style: { width: themeMobile.width },
        }}
      />
      {!loaded && (
        <LinearProgress
          style={{
            width: themeMobile.width,
            marginTop: -15,
            position: "absolute",
          }}
        />
      )}
    </>
  );
};
