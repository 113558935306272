import React, { useState } from "react";
import { Box, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { AttachMoney } from "@material-ui/icons";
import { SaveSaleByLinkButton } from "./components/index";
import { validateCreation } from "./validations/index";
import { styles, loadThemeMobile } from "../../services/styles/index";

import {
  Create,
  Toolbar,
  SimpleForm,
  TextInput,
  SelectArrayInput,
  SelectInput,
} from "react-admin";

import { NewCurrencyInput } from "../../services/inputs/index";
import { paymentList } from "./common";

const useStyles = makeStyles(styles);
const requiredValidate = true;

export const SaleByLinkCreate = (props) => {
  const classes = useStyles();
  const themeMobile = {};
  const [parcelList, setParcelList] = useState([{ id: 1, name: "À Vista" }]);

  const getQuantidadeMaximaParcelas = (valorCompra) => {
    const parcelas = Array.from(Array(12), (_, i) => i + 1);
    for (const quantidade of parcelas.reverse()) {
      if (valorCompra / quantidade >= 10) {
        return quantidade;
      }
    }
    return 1;
  };

  const CreateParcels = (value) => {
    const list = [];
    const parcelas = getQuantidadeMaximaParcelas(
      value ? Number(value.replace(/\D/g, "")) / 100 : 1
    );
    for (let i = 1; i <= parcelas; i++) {
      list.push({
        id: i,
        name: i === 1 ? "À Vista" : `${i}x`,
      });
    }
    setParcelList(list);
  };

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  loadThemeMobile(isSmall, themeMobile, classes);
  return (
    <Create title="Novo Link de Pagamento" {...props}>
      <SimpleForm validate={validateCreation} toolbar={<CustomToolbar />}>
        <SeparatorInit />

        <div style={styles.div_align}>
          <AttachMoney />
          <Typography style={styles.typography_pagamento}>VENDA</Typography>
        </div>

        <TextInput
          label="Valor da Venda"
          source="amount"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          InputProps={{
            inputComponent: NewCurrencyInput,
          }}
          placeholder={styles.placeholder_valores}
          required={requiredValidate}
          onChange={(event) => CreateParcels(event.target.value)}
          autoComplete={"off"}
        />

        <TextInput
          label="Descritivo da Venda"
          source="sale_description"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          multiline
          resettable
        />

        <SelectArrayInput
          label="Tipo do Pagamento"
          source="payment_type"
          choices={paymentList}
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />

        <SelectInput
          label="Parcelamento"
          source="installment_number"
          choices={parcelList}
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />

        <SeparatorInit />
      </SimpleForm>
    </Create>
  );
};

const SeparatorInit = () => <Box pt="1em" />;
const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveSaleByLinkButton />
  </Toolbar>
);
