import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { TextInput, LinearProgress } from "react-admin";
import { onChangeDocumentNumber } from "../index";
import { styles } from "../styles/index";

import {
  DocumentNumberMaskedInput,
  EmailInput,
  PhoneMaskedInput,
} from "../inputs/index";

const useStyles = makeStyles(styles);
const requiredValidate = true;

const DadosClienteTab = () => {
  const form = useForm();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  if (isSmall) {
    themeMobile.widthSmaller = "100%";
    themeMobile.inlineBlock = classes.inlineBlock;
  } else {
    themeMobile.widthSmaller = 256;
    themeMobile.inlineBlock = classes.inlineBlock_marginLeft;
  }

  return (
    <div>
      <div style={styles.div_align}>
        <AccountCircleIcon />
        <Typography style={styles.typography}>CLIENTE</Typography>
      </div>
      <TextInput
        label="CPF/CNPJ"
        source="document_number"
        formClassName={classes.inlineBlock}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        required={requiredValidate}
        onChange={(value) => onChangeDocumentNumber(value, form, setLoading)}
        InputProps={{ inputComponent: DocumentNumberMaskedInput }}
      />
      {isLoading && <LinearProgress style={styles.linear_progress} />}

      <Separator />
      <TextInput
        label="Nome"
        source="client_name"
        formClassName={classes.inlineBlock}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        required={requiredValidate}
      />
      <TextInput
        label="Email"
        source="email"
        formClassName={themeMobile.inlineBlock}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        InputProps={{ inputComponent: EmailInput }}
        required={requiredValidate}
      />
      <TextInput
        label="Telefone"
        source="phone_number"
        formClassName={themeMobile.inlineBlock}
        style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
        InputProps={{ inputComponent: PhoneMaskedInput }}
      />
    </div>
  );
};
const Separator = () => <Box pt="1em" style={{ marginTop: -15 }} />;

export default DadosClienteTab;
