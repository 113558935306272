import React, { useState } from "react";
import { SaveButton, useCreate, useRedirect } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifySuccess = () => {
  return toast.success("Transferência realizada com Sucesso", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: { backgroundColor: "green", opacity: 0.2 },
    autoClose: 3000,
  });
};

const notifyError = (error) => {
  return toast.error(
    error
      ? error.body
      : "Ocorreu um erro ao efetuar o TED verifique os horários disponíveis",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
      progressStyle: { backgroundColor: "red", opacity: 0.2 },
    }
  );
};

toast.configure({
  autoClose: 8000,
  draggable: false,
});

export const SaveTedButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [create] = useCreate("ted");
  const redirectTo = useRedirect();
  const { basePath } = props;

  const handleSave = (values, redirect) => {
    setLoading(true);
    const {
      amount,
      description,
      name,
      document,
      bank_code,
      routing_number,
      routing_check_digit,
      account_number,
      account_check_digit,
    } = values;

    const data = {
      amount: amount
        ? Math.round(
            Number(amount.replace(/[^\d,]+/g, "").replace(",", ".")) * 100 +
              Number.EPSILON
          )
        : 0,
      description,
      recipient: {
        name,
        document: document.replace(/[^\d,]+/g, "").replace(".", "-"),
        bank_code,
        routing_number,
        routing_check_digit,
        account_number,
        account_check_digit,
      },
    };

    create(
      {
        payload: { data },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          setLoading(false);
          notifySuccess();
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
        onFailure: (error) => {
          setLoading(false);
          notifyError(error);
        },
      }
    );
  };

  return (
    <SaveButton
      {...props}
      label={"Transferir"}
      onSave={handleSave}
      saving={loading}
      disabled={loading}
    />
  );
};
