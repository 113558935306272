import Cookies from "universal-cookie";

const cookies = new Cookies();

const url = process.env.REACT_APP_API_URL;
const getDefaultHeaders = () =>
  new Headers({
    Authorization: `Bearer ${cookies.get("accessToken")}`
  });

export { url, getDefaultHeaders };
