import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  usePermissions,
} from "react-admin";

import Card from "@material-ui/core/Card";
import { useMediaQuery } from "@material-ui/core";

import { PermissionBlock } from "../../services/permissions/permissionBlock";
import dataProvider from "../../providers/dataProvider";
import SellerPlan from "./sellerPlans";

const useStyles = makeStyles({
  root: {
    background: "#fff",
  },
});

const style = {
  div: { display: "flex", textAlign: "center" },
  filter: { marginLeft: -8, display: "none" },
};

export const SellerPlanList = (props) => {
  const [sellerPlan, setSellerPlan] = useState();
  const [error, setError] = useState();
  const { permissions } = usePermissions();
  const classes = useStyles();
  const newClass = {};
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchPlan();
  }, []);
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "seller_plan");

    if (found !== "seller_plan") {
      return <PermissionBlock name={"Planos de Taxas"} />;
    }
  }

  if (isSmall) {
    style.div = {};
    newClass.width = "90%";
    newClass.marginLeft = "5%";
    newClass.marginLeftSaldo = "5%";
    newClass.marginTop = 10;
    newClass.marginTopSummary = 10;
    newClass.display_inlineBlock = "";
    newClass.marginRight = 0;
  } else {
    newClass.marginRight = 20;
    newClass.width = 510;
    newClass.marginLeftSaldo = 0;
    newClass.marginTop = 20;
    newClass.marginTopSummary = 20;
    newClass.display_inlineBlock = "inline-block";
    newClass.float = "left";
  }

  const fetchPlan = async () => {
    const { data } = await dataProvider.findSellerPlan();

    if (data.status <= 205) {
      setSellerPlan(data.json);
    }
    if (data.status >= 400) {
      setError(data.body);
    }
  };

  return (
    <Card
      className={classes.root}
      style={{
        background: error ? '#adc7f0' : '',
        display: newClass.display_inlineBlock,
        width: newClass.width,
        marginLeft: newClass.marginLeftSaldo,
        marginTop: newClass.marginTop,
        marginBottom: 0,
        float: newClass.float,
      }}
    >
      <SellerPlan sellerPlan={sellerPlan} error={error} />
    </Card>
  );
};
