export const validateCreation = (values) => {
  const errors = {};

  if (!values.holder_name) {
    errors.holder_name = ["Nome obrigatório"];
  }

  if (!values.document) {
    errors.document = ["CPF/CNPJ obrigatório"];
  }

  if (!values.bank_code) {
    errors.bank_code = ["Código do Banco obrigatório"];
  }

  if (!values.routing_number) {
    errors.routing_number = ["Número da agência obrigatório"];
  }

  if (!values.account_number) {
    errors.account_number = ["Número da conta obrigatório"];
  }

  if (!values.type) {
    errors.type = ["Tipo da Conta obrigatório"];
  }

  if (
    values.document &&
    values.document.replace(/\D/g, '').length < 11
  ) {
    errors.document = ["Número do CPF inválido"];
  }

  return errors;
};
