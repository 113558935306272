import React from "react";
import { Box, Typography, useMediaQuery, makeStyles } from "@material-ui/core";
import { DateInput } from "react-admin-date-inputs";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { AttachMoney, Payment } from "@material-ui/icons";

import {
  Create,
  ArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  usePermissions,
} from "react-admin";

import { SellerSplitSelectInput, SaveNewTransactionButton } from "./components";
import { validateCreation } from "./validations/index";
import { NewCurrencyInput } from "../../services/inputs/index";
import { DadosClient, DadosAdderess } from "../../services/dados/index";
import { styles, loadThemeMobile } from "../../services/styles/index";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const useStyles = makeStyles(styles);
const requiredValidate = true;

export const TransactionsCreate = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user"));
  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  loadThemeMobile(isSmall, themeMobile, classes);

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find(
      (item) => item === "transactionsCreate"
    );

    if (found !== "transactionsCreate") {
      return <PermissionBlock name={"Nova Venda"} />;
    }
  }

  return (
    <Create title="Nova Venda" {...props}>
      <SimpleForm validate={validateCreation} toolbar={<CustomToolbar />}>
        <div style={styles.div_align}>
          <AttachMoney />
          <Typography style={styles.typography}>VALORES</Typography>
        </div>
        <TextInput
          label="Valor"
          source="amount"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          InputProps={{ inputComponent: NewCurrencyInput }}
          placeholder={styles.placeholder_valores}
          autoComplete={"off"}
        />
        <TextInput
          label="Descrição"
          source="description"
          multiline
          resettable
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />
        <DadosClient />
        <DadosAdderess />
        <SeparatorInit />
        <div style={styles.div_align}>
          <Payment />
          <Typography style={styles.typography_pagamento}>PAGAMENTO</Typography>
        </div>
        <DateInput
          label="Data de vencimento do boleto"
          source="expiration_date"
          style={{ width: themeMobile.widthSmaller }}
          required={requiredValidate}
          providerOptions={{ utils: DateFnsUtils, locale: ptBrLocale }}
          options={{ format: "dd/MM/yyyy" }}
          initialvalue={new Date()}
          disablePast={true}
          alwaysOn
          clearable
        />
        {user && user.options && user.options.allow_split && (
          <ArrayInput label="Regras de Split" source="split_rules">
            <SimpleFormIterator>
              <SellerSplitSelectInput
                source="recipient"
                label="Lojista"
                formClassName={themeMobile.inlineBlock_split}
              />
              <TextInput
                label="Valor"
                source="amount"
                formClassName={themeMobile.inlineBlock_split}
                style={{
                  width: themeMobile.widthSmaller,
                  marginLeft: themeMobile.marginLeft,
                }}
                InputProps={{ inputComponent: NewCurrencyInput }}
                placeholder={styles.placeholder_valores}
                autoComplete={"off"}
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </SimpleForm>
    </Create>
  );
};

const SeparatorInit = () => <Box pt="1em" />;
const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveNewTransactionButton />
  </Toolbar>
);
