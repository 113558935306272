import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

function currencyFormatter(value) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return `${amount}`;
}

function CurrencyInput(props) {
  return <NumberFormat {...props} format={currencyFormatter} />;
}

CurrencyInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CurrencyInput;
