import React from "react";
import { FunctionField } from "react-admin";

export const renderInterval = ({ frequency, interval }) => {
  let intervalo = "";
  const validaPlural = (interval) => {
    if (interval > 1) {
      if (frequency === 3) {
        return "es";
      }
      return "s";
    }
    return "";
  };

  switch (frequency) {
    case 1:
      intervalo = `A cada ${interval} dia${validaPlural(interval)}`;
      break;
    case 2:
      intervalo = `A cada ${interval} semana${validaPlural(interval)}`;
      break;
    case 3:
      intervalo = `A cada ${interval} mês${validaPlural(interval)}`;
      break;
    case 4:
      intervalo = `A cada ${interval} ano${validaPlural(interval)}`;
      break;

    default:
      break;
  }
  return <label>{intervalo}</label>;
};

export const IntervalMethodField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      return renderInterval(record);
    }}
  />
);
