const loadThemeMobile = (isSmall, themeMobile, classes) => {
  if (isSmall) {
    themeMobile.widthSmaller = "100%";
    themeMobile.inlineBlock = classes.inlineBlock;
    themeMobile.inlineBlock_split = "";
    themeMobile.marginLeft = "";
  } else {
    themeMobile.widthSmaller = 256;
    themeMobile.inlineBlock = classes.inlineBlock_marginLeft;
    themeMobile.inlineBlock_split = classes.inlineBlock;
    themeMobile.marginLeft = 50;
  }
};

export default loadThemeMobile;
