import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate, useLogin, useNotify, useSafeSetState } from "ra-core";
import forgotPasswordProvider from "../../providers/forgotPasswordProvider";
import confirmPasswordProdiver from "../../providers/confirmPasswordProdiver";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifyError = (error) => {
  return toast.error(
    error
      ? error.msg
      : "Ocorreu um erro ao enviar Email de verificação, por favor tente mais tarde",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
      progressStyle: { backgroundColor: "#f56262", opacity: 0.2 },
    }
  );
};

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: "0 1em 2em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      width: "100%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: "RaLoginForm" }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const LoginForm = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);

  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values) => {
    const errors = { email: undefined, password: undefined };

    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  const [none, setDisplay] = useState("none");
  const [noneConfirm, setDisplayConfirm] = useState("none");
  const [forgotPass, setForgotPass] = useState(false);

  const forgotPassword = async () => {
    const email = document.getElementById("email").value;
    if (email) {
      setForgotPass(true);
      setDisplay("");
      return await forgotPasswordProvider(email, setForgotPass, setDisplay);
    }
    return notifyError({ msg: "Email obrigatório" });
  };

  const confirmPassword = async () => {
    const email = document.getElementById("email").value;
    const code = document.getElementById("code").value;
    const newPassword = document.getElementById("newPassword").value;

    if (email && code && newPassword) {
      setForgotPass(true);
      setDisplay("");
      return await confirmPasswordProdiver(
        email,
        code,
        newPassword,
        setForgotPass,
        setDisplay
      );
    }
    return notifyError({ msg: "Email, nova senha e código são obrigatórios" });
  };

  const submit = (values) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        if (
          error.attributes &&
          error.attributes.status === "EXPIRED_PASSWORD"
        ) {
          setDisplay("");
          setDisplayConfirm("");
        }

        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="email"
                name="email"
                component={Input}
                label={translate("ra.auth.email")}
                type="email"
                disabled={loading}
              />
            </div>

            {!forgotPass && (
              <div className={classes.input}>
                <Field
                  id="password"
                  name="password"
                  component={Input}
                  label={translate("ra.auth.password")}
                  type="password"
                  disabled={loading}
                  autoComplete="current-password"
                />
              </div>
            )}
            {/* Nova senha */}
            <div style={{ display: none }} className={classes.input}>
              <Field
                id="newPassword"
                name="newPassword"
                component={Input}
                label={translate("ra.auth.newPassword")}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
            {/* Confirme a Nova senha */}
            <div style={{ display: noneConfirm }} className={classes.input}>
              <Field
                id="confirmNewPassword"
                name="confirmNewPassword"
                component={Input}
                label={translate("ra.auth.confirmNewPassword")}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
            {/* Code */}
            {forgotPass && (
              <div className={classes.input}>
                <Field
                  id="code"
                  name="code"
                  component={Input}
                  label={"Código de Verificação"}
                  type="text"
                  // disabled={loading}
                />
              </div>
            )}
          </div>
          <CardActions>
            {!forgotPass && (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading || forgotPass}
                className={classes.button}
              >
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                {translate("ra.auth.sign_in")}
              </Button>
            )}

            {forgotPass && (
              <Button
                variant="contained"
                onClick={() => confirmPassword()}
                color="primary"
                disabled={loading}
                className={classes.button}
              >
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                Redefinir Senha
              </Button>
            )}
          </CardActions>
          {!forgotPass && (
            <div
              style={{
                color: "#822150",
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              <label
                style={{ cursor: "pointer" }}
                onClick={() => forgotPassword()}
              >
                Esqueci minha senha?
              </label>
            </div>
          )}
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

export default LoginForm;
