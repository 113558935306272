import { downloadCSV } from "react-admin";

import { format } from "date-fns";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const status = {
  pending: "Pendente",
  succeeded: "Pago",
  canceled: "Cancelado",
  failed: "Falhou",
  paid: "Confirmado",
  scheduled: "Agendado",
};

const type = {
  transfer: "",
  payout_automatic: "Automatico",
  payout_manual: "Manual",
};

const exporter = (posts) => {
  const postsForExport = posts.map((post) => {
    post.Data_Pagamento = format(new Date(post.created_at), "dd/MM/yyyy");
    post.Status = status[post.status];
    post.Tipo_Pagamento = type[post.type];
    post.Descricao = post.description;
    post.Recebimento = post.bank_account
      ? "Banco:" +
        post.bank_account.bank_name +
        "Ag:" +
        post.bank_account.routing_number +
        "Conta:" +
        post.bank_account.account_number
      : "";
    post.Valor = post.amount;

    return post;
  });

  const csv = convertToCSV({
    data: postsForExport,
    fields: [
      "Data_Pagamento",
      "Status",
      "Tipo_Pagamento",
      "Descricao",
      "Recebimento",
      "Valor",
    ],
  });
  downloadCSV(csv, "Transferencias");
};
export default exporter;
