import React, { useState } from "react";
import {
  Filter,
  List,
  Datagrid,
  NumberField,
  TextInput,
  TextField,
  useQueryWithStore,
  FunctionField,
  DeleteButton,
  usePermissions,
} from "react-admin";

import {
  useMediaQuery,
  Backdrop,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { NumberInput } from "../../services/inputs/index";
import DonateListActions from "./components/donateListActions";
import { renderCopy } from "./components/renderCopy";
import { RenderQRCode } from "./components/renderQRCode";
import { renderShortcut } from "./components/renderShortcut";
import { DonateShow } from "./donateShow";
import { PermissionBlock } from "../../services/permissions/permissionBlock";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const DonateList = (props) => {
  const classes = useStyles();
  const [ids, setIds] = useState({});
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const DonateFilter = (props) => {
    const setIdLoading = (record) => setIds({ id: record });
    return (
      <Filter {...props}>
        <TextInput
          label="ID"
          source="id"
          parse={(record) => {
            setIdLoading(record);
            return record;
          }}
          InputProps={{ inputComponent: NumberInput }}
          style={{ marginTop: 40 }}
          alwaysOn
        />
      </Filter>
    );
  };

  const { loading } = useQueryWithStore({
    type: "getList",
    resource: "donate",
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: ids,
    },
  });

  const { permissions } = usePermissions();
  let parsedPermissions = [];

  if (permissions) {
    parsedPermissions = permissions.split(",");
    const found = parsedPermissions.find((item) => item === "donate");

    if (found !== "donate") {
      return <PermissionBlock name={"Doação por Link"} />;
    }
  }

  const FormatValue = (value) => {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <List
      {...props}
      sort={{ field: "id", order: "DESC" }}
      filters={<DonateFilter />}
      title="Listar Doações por Link"
      bulkActionButtons={false}
      actions={<DonateListActions />}
    >
      {isSmall ? (
        loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Datagrid rowClick="expand" expand={<DonateShow />}>
            <TextField source="id" label="ID" />
            <NumberField
              source="amount"
              label="Valor"
              options={{ style: "currency", currency: "BRL" }}
              sortable={false}
            />
            <FunctionField
              style={{ marginLeft: -20, marginRight: -40, padding: 0 }}
              render={(record) => renderCopy(record, isSmall)}
            />
            <FunctionField
              style={{ marginLeft: -30, marginRight: -30, padding: 0 }}
              render={(record) => renderShortcut(record, isSmall)}
            />
            <FunctionField
              style={{ marginLeft: -40, marginRight: -30, padding: 0 }}
              render={(record) => RenderQRCode(record)}
            />
            <DeleteButton style={{ marginLeft: -40, padding: 0 }} />
          </Datagrid>
        )
      ) : loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Datagrid rowClick="expand" expand={<DonateShow />}>
          <TextField source="id" label="ID" />
          <TextField
            label="Descrição da Doação"
            source="sale_description"
            sortable={false}
          />
          <FunctionField
            label="Regras de Valor"
            render={({ value_rules }) => {
              if (value_rules) {
                return `R$ ${FormatValue(value_rules[0])} / R$ ${FormatValue(
                  value_rules[1]
                )} / R$ ${FormatValue(value_rules[2])} / R$ ${FormatValue(
                  value_rules[3]
                )} `;
              }
            }}
          />
          <FunctionField
            style={{ marginRight: -60, padding: 0 }}
            render={(record) => renderCopy(record)}
          />
          <FunctionField
            style={{ marginRight: -40, padding: 0 }}
            render={(record) => renderShortcut(record)}
          />
          <FunctionField
            style={{ marginLeft: -50, padding: 0 }}
            render={(record) => RenderQRCode(record)}
          />
          <DeleteButton style={{ marginLeft: -80, padding: 0 }} />
        </Datagrid>
      )}
    </List>
  );
};
