import { downloadCSV } from "react-admin";

import { format } from "date-fns";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const paymentType = {
  credit: "Credito",
  debit: "Debito",
  boleto: "Boleto Bancario",
  voucher: "Voucher",
};

const exporter = (posts) => {
  const FormatMoney = (value) => {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getReference = (post) => {
    if (post.reference_id) {
      return post.reference_id;
    }
    return post.id;
  };

  const getParcelamento = (post) => {
    if (post.parcela > 0) {
      return ` Parcelado ${post.parcela}/${post.numero_parcelas}`;
    } else if (post.parcela === null && post.payment_type === "credit") {
      return " a Vista";
    }
    return "";
  };

  const postsForExport = posts.items.map((post) => {
    const valorLiquido = post.valor_bruto - post.fees;
    post.Data = format(new Date(post.created_at), "dd/MM/yyyy");
    post.Horario = format(new Date(post.created_at), "HH:mm:ss");
    post.Taxas = "R$ " + FormatMoney(post.fees);
    post.Valor_Bruto = "R$ " + FormatMoney(post.valor_bruto);
    post.Valor_Liquido = "R$ " + FormatMoney(valorLiquido);
    post.Pagamento = paymentType[post.payment_type] + getParcelamento(post);
    post.Referencia = getReference(post);

    return post;
  });

  const csv = convertToCSV({
    data: postsForExport,
    fields: [
      "Data",
      "Horario",
      "Taxas",
      "Valor_Bruto",
      "Valor_Liquido",
      "Pagamento",
      "Referencia",
    ],
  });
  downloadCSV(
    csv,
    "transferencia" +
      format(new Date(posts.date_transfer), "_dd_MM_yyyy'_'HH_mm_ss")
  );
};

export default exporter;
