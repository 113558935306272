export const validateCreation = (values) => {
  const errors = {};
  const document_number = values.document_number
    ? values.document_number.replace(/[^\d,]+/g, "")
    : "";

  if (
    !(
      document_number &&
      (document_number.length === 11 || document_number.length === 14)
    )
  ) {
    errors.document_number = ["CPF/CNPJ obrigatório"];
  }
  if (
    !(
      values.amount &&
      Number(values.amount.replace(/[^\d,]+/g, "").replace(",", "."))
    )
  ) {
    errors.amount = ["Valor obrigatório"];
  }
  if (!values.description) {
    errors.description = ["Descrição obrigatória"];
  }

  if (!values.client_name) {
    errors.client_name = ["Nome obrigatório"];
  }
  if (!values.zip_code) {
    errors.zip_code = ["CEP obrigatório"];
  }
  if (!values.address) {
    errors.address = ["Logradouro obrigatório"];
  }
  if (!values.number) {
    errors.number = ["Número obrigatório"];
  }
  if (!values.state) {
    errors.state = ["Estado obrigatório"];
  }
  if (!values.city) {
    errors.city = ["Cidade obrigatória"];
  }
  if (!values.neighborhood) {
    errors.neighborhood = ["Bairro obrigatório"];
  }
  if (!values.expiration_date) {
    errors.expiration_date = ["Data de vencimento obrigatória"];
  }

  return errors;
};
