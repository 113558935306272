import React, { useState } from "react";
import { SaveButton, useCreate } from "react-admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifySuccess = () => {
  return toast.success("Pagamento efetuado com sucesso", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "foo-bar",
    progressStyle: { backgroundColor: "green", opacity: 0.2 },
    autoClose: 3000,
  });
};

const notifyError = (error) => {
  return toast.error(
    error
      ? error.msg
      : "Saldo insuficiente ou Boleto vencido, por favor verifique a validade",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
      progressStyle: { backgroundColor: "red", opacity: 0.2 },
    }
  );
};

toast.configure({
  autoClose: 8000,
  draggable: false,
});

export const SavePaymentBoleto = (props) => {
  const [loading, setLoading] = useState(false);
  const [create] = useCreate("payment/boleto");

  const handleSave = (values, redirect) => {
    setLoading(true);

    const { bar_code } = values;

    const data = {
      bar_code,
    };

    create(
      {
        payload: { data },
      },
      {
        onSuccess: ({ data }) => {
          setLoading(false);
          notifySuccess();
        },
        onFailure: (error) => {
          setLoading(false);
          notifyError(error.body);
        },
      }
    );
  };

  return (
    <SaveButton
      {...props}
      label={"Pagar Boleto"}
      onSave={handleSave}
      saving={loading}
      disabled={loading}
    />
  );
};
