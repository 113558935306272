import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { TableRow, TableCell, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQueryWithStore, LinearProgress } from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import DateRangeIcon from "@material-ui/icons/DateRange";

const useStyles = makeStyles({
  table: { display: "inherit" },
  numberFormat: {
    background: "#fff",
    borderStyle: "none",
    display: "flex",
  },
  spanFormat: {
    opacity: 0.8,
    display: "flex",
  },
  rowTitle: {
    background: "#822150",
    display: "flex",
  },
  rowContent: {
    background: "#fff",
    width: "100%",
  },
  titleColor: {
    color: "#fff",
  },
  columnContent: {
    width: "100%",
  },
  menuButton: {
    marginTop: -3,
    float: "left",
  },
});

export const Summary = (props) => {
  const [state, setState] = useState();
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dataPerType = {};

  const newClass = {
    rowTitle: { maxHeight: 73 },
    iconColor: { color: "#fff" },
    noBorder: { border: "none" },
  };

  useEffect(() => {
    if (state) {
      let display = document.getElementById("div_filter").style.display;
      if (display === "none") {
        document.getElementById("div_filter").style.display = "block";
      } else {
        document.getElementById("div_filter").style.display = "none";
      }
      setState(false);
    }
  }, [state]);

  const { error, loaded, data } = useQueryWithStore({
    type: "getSummary",
    payload: { filter: props, pagination: { page: 1, perPage: 1 } },
  });

  const formatSales = (sales) => {
    return {
      count: parseFloat(sales.count.toFixed(2)),
      media_amount: parseFloat(sales.media_amount.toFixed(2)),
      total_amount: parseFloat(sales.total_amount.toFixed(2)),
    };
  };

  const formatNetValues = (valueBruto, values) => {
    return {
      valor_liquido: parseFloat(valueBruto) - parseFloat(values.valor_liquido),
    };
  };

  if (data) {
    if (data.dataPerType.length > 0) {
      data.dataPerType.forEach((sales) => {
        switch (sales.payment_type) {
          case "credit":
            dataPerType.credit = formatSales(sales);
            break;
          case "debit":
            dataPerType.debit = formatSales(sales);
            break;
          case "boleto":
            dataPerType.boleto = formatSales(sales);
            break;
          case "voucher":
            dataPerType.voucher = formatSales(sales);
            break;
          case "pix":
            dataPerType.pix = formatSales(sales);
            break;

          default:
            break;
        }
      });
    }
    if (data.dataNetValue.netValuePerType.length > 0) {
      data.dataNetValue.netValuePerType.forEach((values) => {
        switch (values.payment_type) {
          case "credit":
            if (dataPerType.credit) {
              if (dataPerType.credit.total_amount) {
                dataPerType.credit_liquid = formatNetValues(
                  dataPerType.credit.total_amount,
                  values
                );
              }
            }
            break;
          case "debit":
            if (dataPerType.debit) {
              if (dataPerType.debit.total_amount) {
                dataPerType.debit_liquid = formatNetValues(
                  dataPerType.debit.total_amount,
                  values
                );
              }
            }
            break;
          case "boleto":
            if (dataPerType.boleto) {
              if (dataPerType.boleto.total_amount) {
                dataPerType.boleto_liquid = formatNetValues(
                  dataPerType.boleto.total_amount,
                  values
                );
              }
            }
            break;
          case "voucher":
            if (dataPerType.voucher) {
              if (dataPerType.voucher.total_amount) {
                dataPerType.voucher_liquid = formatNetValues(
                  dataPerType.voucher.total_amount,
                  values
                );
              }
            }
            break;
          case "pix":
            if (dataPerType.pix) {
              if (dataPerType.pix.total_amount) {
                dataPerType.pix_liquid = formatNetValues(
                  dataPerType.pix.total_amount,
                  {
                    valor_liquido: 0,
                  }
                );
              }
            }
            break;

          default:
            break;
        }
      });
    }

    data.dataPerType.total_liquido =
      parseFloat(data.dataSales[0].total_amount) -
      parseFloat(data.dataNetValue.netValue.valor_liquido);
  }

  if (isSmall) {
    newClass.fontSizeNumber = "0.9em";
    newClass.fontSizeSpan = "80%";
    newClass.fontSizeTitle = "165%";
    newClass.maxHeight = 30;
    newClass.paddingLeftTitle = 0;
    newClass.paddingTopTitle = 22;
    newClass.marginLeftButton = -3;
    newClass.paddingLeftNVendasCredit = 40;
    newClass.paddingLeftNVendasDebit = 13.2;
    newClass.paddingLeftNVendasBoleto = 44;
    newClass.paddingLeftNVendasVoucher = 5;
    newClass.paddingLeftNVendasPix = 65;
    newClass.widthLoading = "100%";
    newClass.paddingLeftValorBrutoInit = 23;
    newClass.paddingLeftValorLiquidoInit = 12;
    newClass.paddingLeftValorBruto = 22;
    newClass.paddingLeftValorLiquido = 11;

    if (data) {
      if (data.dataSales[0].count > 0) {
        newClass.paddingLeftQuantidade = 18;
      } else {
        newClass.paddingLeftQuantidade = 42;
      }
    }
  } else {
    newClass.fontSizeNumber = 18;
    newClass.fontSizeSpan = "100%";
    newClass.fontSizeTitle = "210%";
    newClass.maxHeight = 50;
    newClass.paddingLeftTitle = 40;
    newClass.paddingTopTitle = 15;
    newClass.marginLeftButton = 0;
    newClass.paddingLeftNVendasCredit = 80;
    newClass.paddingLeftNVendasDebit = 85;
    newClass.paddingLeftNVendasBoleto = 86;
    newClass.paddingLeftNVendasVoucher = 74;
    newClass.paddingLeftNVendasPix = 112.5;
    newClass.widthLoading = 500;
    newClass.paddingLeftValorBrutoInit = 29;
    newClass.paddingLeftValorLiquidoInit = 15;
    newClass.paddingLeftValorBruto = 27;
    newClass.paddingLeftValorLiquido = 13;
    newClass.widthTable = 500;

    if (data) {
      if (data.dataSales[0].count > 0) {
        newClass.paddingLeftQuantidade = 23;
      } else {
        newClass.paddingLeftQuantidade = 52.6;
      }
    }
  }

  newClass.widthNumberInput = 200;

  const Loading = () => (
    <div className={classes.table}>
      <LinearProgress
        style={{
          width: newClass.widthLoading,
          position: "flex",
          display: "flex",
        }}
      />
    </div>
  );

  const Payments = (PaymentType) => {
    let tipoDeVenda, numeroVendas, valorBruto, valorLiquido;
    let stylePayments = {};

    if (PaymentType.credit) {
      tipoDeVenda = "Crédito";
      stylePayments.paddingLeft = newClass.paddingLeftNVendasCredit;
      numeroVendas = dataPerType.credit.count;
      valorBruto = FormatMoney(dataPerType.credit.total_amount);
      valorLiquido = FormatMoney(dataPerType.credit_liquid.valor_liquido);
    } else if (PaymentType.boleto) {
      tipoDeVenda = "Boleto";
      stylePayments.paddingLeft = newClass.paddingLeftNVendasBoleto;
      numeroVendas = dataPerType.boleto.count;
      valorBruto = FormatMoney(dataPerType.boleto.total_amount);
      valorLiquido = FormatMoney(dataPerType.boleto_liquid.valor_liquido);
    } else if (PaymentType.debit) {
      tipoDeVenda = "Débito";
      stylePayments.paddingLeft = newClass.paddingLeftNVendasDebit;
      numeroVendas = dataPerType.debit.count;
      valorBruto = FormatMoney(dataPerType.debit.total_amount);
      valorLiquido = FormatMoney(dataPerType.debit_liquid.valor_liquido);
    } else if (PaymentType.voucher) {
      tipoDeVenda = "Voucher";
      stylePayments.paddingLeft = newClass.paddingLeftNVendasVoucher;
      numeroVendas = dataPerType.voucher.count;
      valorBruto = FormatMoney(dataPerType.voucher.total_amount);
      valorLiquido = FormatMoney(dataPerType.voucher_liquid.valor_liquido);
    } else if (PaymentType.pix) {
      tipoDeVenda = "Pix";
      stylePayments.paddingLeft = newClass.paddingLeftNVendasPix;
      numeroVendas = dataPerType.pix.count;
      valorBruto = FormatMoney(dataPerType.pix.total_amount);
      valorLiquido = FormatMoney(dataPerType.pix_liquid.valor_liquido);
    }

    return (
      <TableRow>
        <div style={{ paddingLeft: 15, margin: 0, width: "182%" }}>
          <span
            className={classes.spanFormat}
            style={{
              fontSize: newClass.fontSizeSpan,
              display: "inline-block",
            }}
          >
            Tipo de Venda:
          </span>
          <strong
            style={{
              fontSize: newClass.fontSizeSpan,
              paddingLeft: 4,
              display: "inline-block",
            }}
          >
            {tipoDeVenda}
          </strong>

          <span
            className={classes.spanFormat}
            style={{
              fontSize: newClass.fontSizeSpan,
              paddingLeft: stylePayments.paddingLeft,
              display: "inline-block",
            }}
          >
            N° de Vendas:
          </span>
          <NumberFormat
            className={classes.numberFormat}
            style={{
              fontSize: newClass.fontSizeNumber,
              width: 60,
              paddingLeft: 5,
              display: "inline-block",
            }}
            value={numeroVendas}
            disabled
          />
        </div>

        <div style={{ paddingLeft: 15, margin: 0, width: "182%" }}>
          <span
            className={classes.spanFormat}
            style={{
              fontSize: newClass.fontSizeSpan,
              display: "inline-block",
            }}
          >
            Valor Bruto:
          </span>
          <NumberFormat
            className={classes.numberFormat}
            style={{
              fontSize: newClass.fontSizeNumber,
              paddingLeft: newClass.paddingLeftValorBruto,
              display: "inline-block",
              width: newClass.widthNumberInput,
            }}
            allowedDecimalSeparators={[",", "."]}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"R$ "}
            value={valorBruto}
            disabled
          />
        </div>
        <div style={{ paddingLeft: 15, margin: 0, width: "182%" }}>
          <span
            className={classes.spanFormat}
            style={{
              fontSize: newClass.fontSizeSpan,
              display: "inline-block",
            }}
          >
            Valor Líquido:
          </span>
          <NumberFormat
            className={classes.numberFormat}
            style={{
              fontSize: newClass.fontSizeNumber,
              display: "inline-block",
              paddingLeft: newClass.paddingLeftValorLiquido,
              width: newClass.widthNumberInput,
            }}
            allowedDecimalSeparators={[",", "."]}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"R$ "}
            value={valorLiquido}
            disabled
          />
        </div>
      </TableRow>
    );
  };

  if (data) {
    return (
      <table className={classes.table} style={{ width: newClass.widthTable }}>
        <TableRow className={classes.rowTitle} style={newClass.rowTitle}>
          <TableCell style={newClass.noBorder}>
            <IconButton
              className={classes.menuButton}
              style={{ marginLeft: newClass.marginLeftButton }}
              aria-label="open drawer"
              onClick={() => setState(true)}
            >
              <DateRangeIcon style={newClass.iconColor} />
            </IconButton>
          </TableCell>
          <TableCell
            style={{
              maxHeight: newClass.maxHeight,
              paddingLeft: newClass.paddingLeftTitle,
              paddingTop: newClass.paddingTopTitle,
              border: "none",
            }}
          >
            <span
              className={classes.titleColor}
              style={{ fontSize: newClass.fontSizeTitle }}
            >
              Resumo de Vendas
            </span>
          </TableCell>
        </TableRow>

        <TableRow>
          <div style={{ paddingLeft: 15, margin: 0, width: "182%" }}>
            <span
              className={classes.spanFormat}
              style={{
                fontSize: newClass.fontSizeSpan,
                display: "inline-block",
              }}
            >
              Valor Bruto:
            </span>
            <NumberFormat
              className={classes.numberFormat}
              style={{
                fontSize: newClass.fontSizeNumber,
                paddingLeft: newClass.paddingLeftValorBrutoInit,
                display: "inline-block",
                width: newClass.widthNumberInput,
              }}
              allowedDecimalSeparators={[",", "."]}
              thousandSeparator="."
              decimalSeparator=","
              prefix={"R$ "}
              value={
                data.dataSales[0].total_amount
                  ? FormatMoney(data.dataSales[0].total_amount)
                  : 0
              }
              disabled
            />
          </div>

          <div style={{ paddingLeft: 15, margin: 0, width: "182%" }}>
            <span
              className={classes.spanFormat}
              style={{
                fontSize: newClass.fontSizeSpan,
                display: "inline-block",
              }}
            >
              Valor Líquido:
            </span>
            <NumberFormat
              className={classes.numberFormat}
              style={{
                fontSize: newClass.fontSizeNumber,
                paddingLeft: newClass.paddingLeftValorLiquidoInit,
                display: "inline-block",
                width: newClass.widthNumberInput,
              }}
              allowedDecimalSeparators={[",", "."]}
              thousandSeparator="."
              decimalSeparator=","
              prefix={"R$ "}
              value={
                data.dataPerType.total_liquido
                  ? FormatMoney(data.dataPerType.total_liquido)
                  : 0
              }
              disabled
            />
          </div>
          <div style={{ paddingLeft: 15, margin: 0, width: "182%" }}>
            <span
              className={classes.spanFormat}
              style={{
                fontSize: newClass.fontSizeSpan,
                paddingLeft: 0,
                display: "inline-block",
              }}
            >
              Quantidade:
            </span>
            <NumberFormat
              className={classes.numberFormat}
              style={{
                fontSize: newClass.fontSizeNumber,
                width: newClass.widthNumberInput,
                paddingLeft: newClass.paddingLeftQuantidade,
                display: "inline-block",
              }}
              value={data.dataSales[0].count}
              disabled
            />
          </div>
        </TableRow>

        <Border />
        {dataPerType.credit && (
          <Payments
            {...{
              credit: true,
              debit: false,
              boleto: false,
              voucher: false,
              pix: false,
            }}
          />
        )}
        <Border />
        {dataPerType.debit && (
          <Payments
            {...{
              credit: false,
              debit: true,
              boleto: false,
              voucher: false,
              pix: false,
            }}
          />
        )}
        <Border />
        {dataPerType.boleto && (
          <Payments
            {...{
              credit: false,
              debit: false,
              boleto: true,
              voucher: false,
              pix: false,
            }}
          />
        )}
        <Border />
        {dataPerType.voucher && (
          <Payments
            {...{
              credit: false,
              debit: false,
              boleto: false,
              voucher: true,
              pix: false,
            }}
          />
        )}
        <Border />
        {dataPerType.pix && (
          <Payments
            {...{
              credit: false,
              debit: false,
              boleto: false,
              voucher: false,
              pix: true,
            }}
          />
        )}
      </table>
    );
  } else if (!loaded) {
    return <Loading />;
  } else if (error) {
    return <Loading />;
  } else {
    return <Loading />;
  }
};

const FormatMoney = (value) => {
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const Border = () => (
  <div
    style={{
      borderBottom: `1px solid
      rgba(224, 224, 224, 1)`,
      width: "100%",
    }}
  ></div>
);
