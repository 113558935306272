import React from "react";
import logo from "./logo.png";

function Header() {
  return (
    <a href="#/" style={{ filter: "invert(1)" }}>
      <img
        alt="logo"
        src={logo}
        style={{ filter: "brightness(0)" }}
        width="200"
      ></img>
    </a>
  );
}
export default Header;
