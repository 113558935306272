import React from "react";
import { Box, Typography, makeStyles, useMediaQuery } from "@material-ui/core";

import { AttachMoney, ReceiptOutlined } from "@material-ui/icons";
import { SaveDonateButton } from "./components/index";
import { validateCreation } from "./validations/index";
import { styles, loadThemeMobile } from "../../services/styles/index";
import { NewCurrencyInput } from "../../services/inputs/index";

import {
  Create,
  Toolbar,
  SimpleForm,
  TextInput,
  SelectArrayInput,
} from "react-admin";

import { paymentList } from "./common";

const useStyles = makeStyles(styles);
const requiredValidate = true;

export const DonateCreate = (props) => {
  const classes = useStyles();
  const themeMobile = {};

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  loadThemeMobile(isSmall, themeMobile, classes);
  return (
    <Create title="Novo Link de Pagamento" {...props}>
      <SimpleForm validate={validateCreation} toolbar={<CustomToolbar />}>
        <div style={styles.div_align}>
          <ReceiptOutlined />
          <Typography style={styles.typography_pagamento}>
            DADOS DO LINK
          </Typography>
        </div>

        <TextInput
          label="Descritivo da Doação"
          source="sale_description"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
          multiline
          resettable
        />

        <SelectArrayInput
          label="Tipo do Pagamento"
          source="payment_type"
          choices={paymentList}
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          required={requiredValidate}
        />

        <SeparatorInit />
        <div style={styles.div_align}>
          <AttachMoney />
          <Typography style={styles.typography_pagamento}>VALORES</Typography>
        </div>
        <TextInput
          label="1° Valor"
          source="value_rule_01"
          name="value_rule_01"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          InputProps={{
            inputComponent: NewCurrencyInput,
          }}
          placeholder={"Valor mínimo R$ 20,00"}
          required={requiredValidate}
          autoComplete={"off"}
        />
        <TextInput
          label="2° Valor"
          source="value_rule_02"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          InputProps={{
            inputComponent: NewCurrencyInput,
          }}
          placeholder={"Valor mínimo R$ 20,00"}
          required={requiredValidate}
          autoComplete={"off"}
        />
        <TextInput
          label="3° Valor"
          source="value_rule_03"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          InputProps={{
            inputComponent: NewCurrencyInput,
          }}
          placeholder={"Valor mínimo R$ 20,00"}
          required={requiredValidate}
          autoComplete={"off"}
        />
        <TextInput
          label="4° Valor"
          source="value_rule_04"
          formClassName={themeMobile.inlineBlock_split}
          style={{ width: themeMobile.widthSmaller, paddingRight: 50 }}
          InputProps={{
            inputComponent: NewCurrencyInput,
          }}
          placeholder={"Valor mínimo R$ 20,00"}
          required={requiredValidate}
          autoComplete={"off"}
        />
      </SimpleForm>
    </Create>
  );
};

const SeparatorInit = () => <Box pt="1em" />;
const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveDonateButton />
  </Toolbar>
);
